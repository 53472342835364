import { api } from '../api'

export interface Theme {
    id: string,
    name: string,
    created_by: string,
    image?:string,
    code?: string,
    title?: string,
    type?: string,
    created_at?: string,
    updated_at?:string,
    amplifyDefaultDomain?:string,
    business_code?: string

}
export interface ThemesResponse {
  Items: Theme[],
  lastEvaluatedKey?: any
}
export const themeApi = api.injectEndpoints({
    endpoints: (build) => ({
      getThemes: build.query<ThemesResponse, string>({
        query: ( params:any ) => ({ url: 'theme?'+params }),
        providesTags: (result={ Items :[]}) => [
          ...(result.Items).map(({ id }) => ({ type: 'Theme', id }) as const),
          { type: 'Themes' as const, id: 'LIST' },
        ],
      }),
      addTheme: build.mutation<Theme, Partial<Theme>>({
        query: (body) => ({
          url: `theme`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'Themes', id: 'LIST' }],
      }),
      getTheme: build.query<Theme, string>({
        query: (params) => `theme?${params}`,
        providesTags: (_post, _err, id) => [{ type: 'Theme', id }],
      }),
      updateTheme: build.mutation<Theme, Partial<Theme>>({
        query(data) {
          return {
            url: `theme`,
            method: 'PATCH',
            body:data,
          }
        },
        invalidatesTags: (data) => [{ type: 'Theme', id: data?.id }],
      }),
      deleteTheme: build.mutation<{ success: boolean; id: string }, Partial<Theme>>({
        query(data) {
            console.log('delete',data);
          return {
            url: `theme`,
            method: 'DELETE',
            body:data
          }
        },
        invalidatesTags: (theme) => [{ type: 'Themes', id: theme?.id }],
      }),
      publishTheme: build.mutation<Theme, Partial<Theme>>({
        query(data) {
          return {
            url: `theme/publish`,
            method: 'POST',
            body:data,
          }
        },
        invalidatesTags: (data) => [{ type: 'Theme', id: data?.id }],
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useAddThemeMutation,
    useDeleteThemeMutation,
    useGetThemesQuery,
    useGetThemeQuery,
    useUpdateThemeMutation,
    usePublishThemeMutation,
    useGetErrorProneQuery,
  } = themeApi
  
  export const {
    endpoints: { getTheme,getThemes,deleteTheme,updateTheme,addTheme,publishTheme },
  } = themeApi
  