import React, { useContext } from 'react';
import { UserContext } from '../../../utils/UserContext';

function BusinessList() {
    const { user } = useContext(UserContext);
    
    return (
        <div className="lg:bg-[#99defd]  h-screen flex justify-center items-center lg:p-10">

            <div className=" flex flex-col justify-center gap-4 px-10 py-5  rounded-xl md:w-2/5 font-san bg-white ">
                {user.user_data.map((obj: any) => (
                    <div className="flex flex-col">
                        <label className="text-neutral-800">
                            {obj.business_name}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default BusinessList;