// merge two objects object1 and object2 for all nested level
export function merge(object1:any, object2:any) {
    if( typeof object1 == 'undefined'  ) {
        return object2;
    }
    if( typeof object2 == 'undefined'  ) {
        return object1;
    }
  for (const key in object2) {
    
    if (object2.hasOwnProperty(key)) {
        if(typeof object2[key] == 'function' ) {
            object1[key] = object2[key];
        }
        else if (object2[key] instanceof Array) {
            
            // array merge
            //object1[key] = object1[key].concat(object2[key]);
            object1[key] = object2[key];
          }
        else if (object2[key] instanceof Object) {
            
            object1[key] = merge(object1[key], object2[key]);
        } 
        else {
            
            object1[key] = object2[key];
        }
    }
  }
  return object1;
}