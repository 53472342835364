import React, { FC } from 'react';

interface InputProps {
  label?: string;
  type?: string;
  helpText?: string;
  required?: boolean;
  name?: string;
  value?: any;
  id?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = (props) => {
  const { label, id, type, helpText, required, name, value, onChange } = props;

 

  return (
    <div className="mb-5 pr-4 flex flex-col justify-center items-center ">
      {label && <label className="block text-xs font-medium text-gray-700">{label}</label>}

      
      <input
        type={type}
        className="px-3 py-1 rounded-[4px] border border-primary-light focus:outline-none hover:bg-blue-100 
         w-full "
        required={required}
        name={name}
        id={id ?? name}
        value={value}
        onChange={onChange}
      />
    
      {helpText && <p className="text-xs text-gray-500">{helpText}</p>}
  
    </div>
  );
};

export default Input;
