import { Navigate, Outlet, useLocation } from 'react-router-dom'
import AdminLayout from "../features/layouts/admin/admin"

export function BusinessOutlet() {
  const location = useLocation()
  if((localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== 'undefined'))
  {
    var storageData  = JSON.parse(localStorage.getItem('userData') ?? '{}');
    if(storageData.current_business !==undefined){
  
        return <AdminLayout><Outlet /></AdminLayout>;
    }
  }
    return <Navigate to="/business" state={{ from: location }} />
}