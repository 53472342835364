
import Cart from "./Cart/cart"
import { useGetPlanQuery } from "../../../app/services/api/plan";
import {useState} from 'react';
import Faq from "../../home/faq";
import { set } from "immer/dist/internal";


interface Plan {
    pk: string,
    title: string,
    description?: string,
    validity: number,
    billed_type: string,
    price: number,
    sort_order?:number
    badge?: string,
    features?: []
}

const Plan = () =>{
    const [toggle, settoggle]=useState(false);
    const [toggle1, settoggle1]=useState(false);
    const { data: jsonData } = useGetPlanQuery("");
    const isArray = Array.isArray(jsonData);
    return(
        <div className="py-20 px-6  flex flex-col gap-10 bg-[#ebebeb] rounded-md  font-gtbuddy text-[#464646] ">
        <div>
            <h1 className="text-center text-4xl font-bold ">Choose your plan</h1>
            <p className="text-center">Choose your ideal plan and elevate your experience</p>
        </div>
        <div className="flex justify-center items-center flex-wrap md:gap-8 gap-16">
            {isArray && jsonData.map(plan => (
                <Cart
                code ={plan.pk}
                title={plan.title || ""}
                price={plan.price|| ""}
                description={plan.description || ""}
                features={typeof plan.features === 'string' ? [plan.features] : plan.features || []} // Convert to string[] if it's a string
  />
        ))}
        </div>
     
                <Faq/>

        </div>
    )
}

export default Plan