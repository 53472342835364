const ComingSoon = (props: any) => {
    return (
        <div className="flex font-gtbuddy justify-center flex-col rounded-xl m-5">
            <div className=" border-[#ebebeb] flex justify-center items-center gap-16 bg-gradient-to-b from-white to-neutral-400 py-24 px-12 font-sans ">
                <div className="text-3xl font-extrabold  text-center  italic  ">
                    <h1 className="tracking-tighter text-[#1a1a1a]">COMING</h1>
                    <h1 className="tracking-tighter text-white">SOON</h1>
                </div>
                <div>
                    <h1 className="text-6xl font-extrabold text-[#1b1b1b] py-4">{props.heading ?? ""}</h1>
                    <p>{props.description ?? ""}</p>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon