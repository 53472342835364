import ArrowIcon from "./arrowicon.png";
import { NavLink } from "react-router-dom";
import React, { useState, useContext, useEffect, Children } from "react";
import { selectCurrentBusiness } from './../../auth/authSlice';
import { UserContext } from "../../../utils/UserContext";
import { getCurrentBusinessType } from "../../../helper/business";
import { useSelector } from "react-redux";
interface Section {
  name: string;
  path: string;
  children: [];
}

const Sidebar = (props: any) => {
  const { user } = useContext(UserContext);
  const [jsonData, setJsonData] = useState<Section[] | null>(null);
  const [retryCounter, setRetryCounter] = useState(0);
  const currentBusiness = useSelector(selectCurrentBusiness);

  useEffect(() => {
    const loadJsonFile = async () => {
      try {
        const themeType = currentBusiness?.type;
        const url = `/${themeType}/menu.json`;
        const response = await fetch(url);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        if(retryCounter < 2){
          console.log("Retrying loading json data...");
          setRetryCounter(retryCounter+1);
        } else {
          console.error("Error loading JSON file:", error);
          throw error;
        }
      }
    };
      loadJsonFile();
  }, [retryCounter]);
  const [opensublist, setopensublist] = useState(false);
  function openSublist() {
    setopensublist(!opensublist);
  }
  jsonData?.map((item) => console.log(item));
  return (
    <div className="absolute  z-10 md:static flex flex-col h-screen  bg-white   font-gtbuddy  capitalize  w-full text-sm shadow-md  ">
      {jsonData ? (
        jsonData.map((item: Section, i) => (
        
          <ul key={item.name}>
            <NavLink
              to={item.path}
              className={({
                isActive,
              }) => ` flex w-full p-3 hover:bg-blue-100 
                hover:cursor-pointer border-b
                ${isActive ? "bg-blue-50 " : "   "}
                `}
              onClick={item.children ? openSublist : undefined}
            >
              {item.name}
            
            </NavLink>

            {item.children &&
              item.children.map(
                (child: Section, i) =>
                  opensublist && (
                    <li key={child.name}>
                      <NavLink
                        to={child.path}
                        className={({isActive, }) =>
                          `flex gap-2 rounded-md  p-3 font-medium 
                          mt-0.5 text-xs border-b 
                      ${ isActive ? "bg-blue-50 " : "  " }
                      hover:cursor-pointer hover:bg-blue-100   `
                        }
                      >
                        {/* <img src={ArrowIcon} className="h-4" /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"  className="h-3"><path fill="#000000" d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right"/></svg>
                        {child.name}
                      </NavLink>
                    </li>
                  )
              )}
          </ul>
        ))
      ) : (
        <p>Loading JSON data...</p>
      )}
    </div>
  );
};
export default Sidebar;
