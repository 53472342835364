import React, { ReactNode, useEffect, useState } from 'react';
import t from '../../app/services/translation';

interface ModalProps {
  children: ReactNode;
  submitAction?: () => void;
  submitActionLabel?: String;
  isCloseButton?: boolean
  title?: String;
  onClick?: () => void
  submitActionDisabled?: boolean;
  cancelAction?: () => void
}

const Modal: React.FC<ModalProps> = ({ submitActionLabel, submitAction, isCloseButton,
  title, children, onClick, submitActionDisabled, cancelAction }) => {
  const [isOpen, setIsModalOpen] = useState(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    cancelAction && cancelAction();
    onClick && onClick();
  };


  return (
    <>
      {isOpen && (<>
        <div className="fixed inset-0 bg-black opacity-80 z-40"></div>
        <div className="fixed bg-[#ebebeb]  p-8 rounded shadow-lg w-3/5
            top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <div className="text-center mx-8">
            <h2 className="text-lg font-semibold mb-4 text-[#464646]">{title}</h2>

            <div className="mb-4">{children}</div>
            <div className="flex gap-4 justify-end">
              {isCloseButton ?
                <button
                  onClick={handleCloseModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
                >
                  Close Modal
                </button> :
                <div className="absolute top-3 right-3 border border-black p-4
                  flex justify-center items-center rounded-full hover:divide-stone-400 hover:cursor-pointer" onClick={handleCloseModal}>
                  <div className=" h-1 w-6 bg-[#464646] absolute rotate-45"></div>
                  <div className="h-1 w-6 bg-[#464646] absolute -rotate-45"></div>
                </div>
              }
              {submitAction && (<button
                onClick={submitAction}
                disabled={submitActionDisabled}
                type="button"
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none disabled:hover:bg-gray-500"
              >
                {submitActionLabel ?? t("Save")}
              </button>
              )}
            </div>
          </div>
        </div>
      </>
      )}
    </>
  );
};

export default Modal;
