import React, { useState } from 'react';
import openEye from '../../../images/open-eye.png';
import closedEye from '../../../images/closed-eye.png';
import { createUnparsedSourceFile } from 'typescript';

const PasswordInput = (props:any) => {
    const [password,setPassword] = useState('');
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const [showPassward,setshowPassward] = useState({
        showPassward:true,
        setshowPassward: false
    });
    const lowerCase= new RegExp('(?=.*[a-z])');
    const upperCase= new RegExp('(?=.*[A-Z])');
    const specialChar=new RegExp('(?=.*[!@#$%^&*])');
    const lengthPassword=new RegExp('^.{7,}\.$');
    const digit= new RegExp('(?=.*[0-9])')


    const handlePasswardShow = () => {
        showPassward.showPassward ? setshowPassward({showPassward:false,
            setshowPassward: false}): setshowPassward({showPassward:true,
                setshowPassward: false});

    }
  return (
    <>
        
        <div  className="flex flex-col">
            <label className="text-neutral-800">
                {props.label}
            </label>
            <div className="relative">
                <input
                    className="p-3 rounded-xl focus:outline-none focus:border-blue-500 border w-full border-neutral-950 text-neutral-700 z-10  "
                    type={showPassward.showPassward ? "password" : "text"}
                    id="password"
                    required
                    value={password} 
                    onChange={(e) => {
                        props.onChange(e.target.value)
                        setPassword(e.target.value)
                }}
                />
                <span className="top-0 right-0 m-2 absolute">
                    <img id="eye1" onClick={handlePasswardShow} src={closedEye} className={showPassward.showPassward ? "closedEye h-8 w-8 text-end" : "textclosedEye h-8 w-8 text-end hidden"} alt="show" />
                    <img id="eye2" onClick={handlePasswardShow} src={openEye} className={showPassward.showPassward ? "closedEye h-8 w-8 text-end hidden" : "textclosedEye h-8 w-8 text-end"} alt="open" />
                </span>
            </div>
        </div>
        {/* <div className={passwordRegex.test(password) ? "h-2 rounded-l-full bg-green-400 w-8/8" : "h-2 rounded-l-full bg-red-400 w-"+password.length+"/8"}>
        </div> */}
       
        <div className={!passwordRegex.test(password) ?(password ? "flex flex-col text-neutral-500":"hidden"):"hidden"}>
        <div>
        <p className="text-neutral-800">Your password must be at least </p>
        </div>
            <div className=" flex items-center gap-2">
                <div className={lengthPassword.test(password) ? "h-3 w-3 rounded-full bg-green-400":"h-3 w-3 rounded-full bg-red-400"}></div>
                <p>Eight characters</p>
            </div>

            <div className=" flex items-center gap-2">
                <div className={upperCase.test(password) ? "h-3 w-3 rounded-full bg-green-400":"h-3 w-3 rounded-full bg-red-400"}></div>
                <p>One uppercase</p>
            </div>

            <div className=" flex items-center gap-2">
                <div className={lowerCase.test(password) ? "h-3 w-3 rounded-full bg-green-400":"h-3 w-3 rounded-full bg-red-400"}></div>
                <p>One lowercase</p>
            </div>

            <div className=" flex items-center gap-2">
                <div className={specialChar.test(password) ? "h-3 w-3 rounded-full bg-green-400":"h-3 w-3 rounded-full bg-red-400"}></div>
                <p>One special characters</p>
            </div>

            <div className=" flex items-center gap-2">
                <div className={digit.test(password) ? "h-3 w-3 rounded-full bg-green-400":"h-3 w-3 rounded-full bg-red-400"}></div>
                <p>One digit</p>
            </div>

            
           
        </div>
    </>
  );
};

export default PasswordInput;



