
import { Link, useParams } from "react-router-dom";
import Form from "../forms/Form";
import { EditFields } from "./EditFields";

type EditProps = {
    form: {
        title: string,
        fields?: any,
    },
    fields: any,
    handleSubmit: any,
    FormExtraFields?: any,
    FormExtraLinks?: any,
}
export const Edit = (props: EditProps) => {
    //let data: any = useLoaderData();
    let { id } = useParams();
    // random id
    let randomId = Math.floor(Math.random() * 10000);
    let formId = 'form-' + randomId;
    if (id) {
        formId = id;
    }



    //const handleSubmit = (e: any) => {
    /*e.preventDefault();
    if (true) {
        dispatch(addToast({ type: 'success', message: props.form.title + ' added successfully.' }));
        navigate('./../');
    }
    else {
        dispatch(addToast({ type: 'success', message: props.form.title + ' updated successfully.' }));
        navigate('./../../');
    }
    */
    //}
    //console.log('props.form.fields', props.form.fields);

    return (
        <div className="flex flex-col  m-6 gap-10 font-sans ">


            <div className="z-20 sticky top-0 flex justify-between items-center px-3 py-2 bg-[#fff]">
                <h2 className="text-[#303030] font-bold text-xl">{props.form.title}</h2>
                {
                    props.FormExtraLinks && <props.FormExtraLinks form={props.form} formId={formId} />
                }
                <Link
                    to={'./edit'}
                    onClick={(e) => {
                        e.preventDefault();

                        // get form and trigger submit
                        let form = document.getElementById(formId);
                        if (form) {
                            form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                        }
                    }}
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 cursor-pointer">
                    Save
                </Link>


            </div>
            <div className="bg-[#ffffff] rounded-lg relative flex flex-col ">
                <Form handleSubmit={props.handleSubmit} id={formId} >
                    <fieldset className="mb-6 bg-white  rounded px-8 pt-8 pb-8 ">
                        <legend className="block font-bold mb-2 text-gray-700">{props.form.title} Information</legend>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <EditFields fields={props.form.fields} />
                        </div>
                    </fieldset>
                    {
                        props.FormExtraFields && <fieldset className="mb-6 bg-white  rounded px-8 pt-8 pb-8 ">
                            <props.FormExtraFields form={props.form} />
                        </fieldset>
                    }

                </Form>
            </div>
        </div>
    );
}

export default Edit;