import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { api } from '../api'

export interface S3Url {
    data?:any,
    id?:string|null,
    url?:string,
    key?:string

}
export const s3Api = api.injectEndpoints({
    endpoints: (build) => ({
      getSignedUrl: build.query<S3Url, string>({
        query: (params) => `signed-s3?json=1&${params}`,
      }),
      delete: build.query<S3Url, string>({
        query: (params) => `signed-s3?${params}`,
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useGetSignedUrlQuery
  } = s3Api
  
  export const {
    endpoints: { getSignedUrl },
  } = s3Api
  