import { setUseProxies } from "immer";
import { useState } from "react";
import { UpDown } from "../icons/UpDown";

type propsType = {
    heading: any
    children: any
}
export const CollapsibleContent = ({ heading, children }: propsType) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = (e: any) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
    }
    return (
        <div className="border border-gray-200 rounded-md">
            <div className="px-4 py-1 flex justify-between items-center bg-gray-50">
                <h2 className="text-lg font-semibold">{heading}</h2>
                <button className="text-blue-500" onClick={toggleCollapse}>
                    {isCollapsed ? <UpDown direction="down" /> : <UpDown direction="up" />}
                </button>
            </div>
            {!isCollapsed && (
                <div className="p-4 bg-white">
                    {children}
                </div>
            )}
        </div>
    );
}