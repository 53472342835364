import React, { useState } from 'react';
import type { User,ConfirmationData } from '../../app/services/auth'
import { useDialCode } from '../../hooks/useDialCode'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router';
import {
    addToast
} from '../toast/toastSlice';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
import PasswordInput from './form/PasswordInput';
import logo from '../../images/logo.png'
//https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js
type Props = {
    user: User,
    confirmationData: ConfirmationData,
    setFormType:any
  }
function Forgot({ user, confirmationData, setFormType }:Props) {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    var poolData = {
        UserPoolId: process.env.REACT_APP_POOL_ID ?? '', // Your user pool id here
        ClientId: process.env.REACT_APP_CLIENT_ID ?? '', // Your client id here
    };
    let cognitoUser:any;
    var userPool = new CognitoUserPool(poolData);
    var userData = {
        Username: '',
        Pool: userPool,
    };
    console.log(userData);
    cognitoUser = new CognitoUser(userData);

    
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmationCode , setConfirmationCode] = useState('');
    const [localFormType, setLocalFormType] = useState('forgot');
    const [cUser,setCUser] = useState(cognitoUser);

    const handlPassword = (value:any) => {
        setNewPassword(value);
    };
    
    const forgotPassword = () => {
       
        var userData = {
            Username: email,
            Pool: userPool,
        };
        cognitoUser = new CognitoUser(userData);
        setCUser(cognitoUser);
        cognitoUser.forgotPassword({
            onSuccess: function(data:any) {
                // successfully initiated reset password request
                setLocalFormType('update');
                
                console.log('CodeDeliveryData from forgotPassword: ' + data);
            },
            onFailure: function(err:any) {
                alert(err.message || JSON.stringify(err));
            },
            //Optional automatic callback
            inputVerificationCode: function(data:any) {
                console.log('Code sent to: ');
                setLocalFormType('update');
                dispatch(addToast({type:"success",message:'Confirmation code sent to your email'}));
                console.log(data);
                /*cognitoUser.confirmPassword(verificationCode, newPassword, {
                    onSuccess() {
                        console.log('Password confirmed!');
                    },
                    onFailure(err) {
                        console.log('Password not confirmed!');
                    },
                });
                */
            },
        });
    }
    const updatePassword = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if(!passwordRegex.test(newPassword))
        {
            dispatch(addToast({type:"error",message:'Invalid password format'}));
            return;
        }
        cUser.confirmPassword(confirmationCode, newPassword, {
            onSuccess() {
                dispatch(addToast({type:"success",message:'Pasword updated successfully'}));
                setFormType("login");
            },
            onFailure(err:any) {
                dispatch(addToast({type:"error",message:'Password not confirmed!'}));
            },
        });
    }
    const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        forgotPassword();
    }
    function sendConfirmation() {

        forgotPassword();
    }
    const ForgotForm = () => {
        return (
            <form className="my-8 space-y-6" onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST">
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                            Email
                                    </label>
                        <input
                            className="border border-gray-400 p-2 rounded-lg w-full"
                            type="email"
                            id="email"
                            value={email}
                            required
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                    </div>

                    <button
                        
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4  font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
                    >
                        Submit
                        </button>
                        
                </form>
        );
    }

    const ResetPassword = () => {
        return (
            <form className="mt-8 space-y-6" onSubmit={(e) => { updatePassword(e); }} action="#" method="POST">
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2" htmlFor="confirmPassword">
                            Confirmation Code
                                    </label>
                        <input
                            className="border border-gray-400 p-2 rounded-lg w-full"
                            type="text"
                            id="confirmation-code"
                            value={ confirmationCode }
                            required
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                   
                    </div>
                    <PasswordInput onChange={handlPassword} label="New Password"/>
                    <button
                        
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Submit
                        </button>
                        <button
                            className=" text-indigo-500 my-5 py-2 px-4 "
                            onClick={(e) => { e.stopPropagation(); sendConfirmation();  }}
                        >
                            Resend confirmation code
                        </button>
                </form>
        );
    }
    return (


        <>
            <div className="min-w-full">
            <div className=" flex items-center gap-1 justify-center py-1">
                <img  src={logo}  className=" h-8 w-8  " alt=""/>
                <h1 className="font-bold text-2xl"><span>GT</span><span className=" text-[#ea7a00]">BUDDY</span></h1>
        </div>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Forgot password 
                </h2>
                { confirmationData.Destination && <div>Confirmation code is sent on {confirmationData.Destination} </div>}
                { localFormType == 'forgot' ? ForgotForm() : ResetPassword() } 
            </div>
        </>
    );
}
export default Forgot;