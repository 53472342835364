import React, { useState, useRef } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "../../../modal/Modal";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCropper =  (props: any) => {

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);
  let imagePrevUrl =props.imagePreview ?? "";
//  console.log(imagePrevUrl,'imagePrevUrl')
  const [prevImgSrc, setPrev] = useState(imagePrevUrl);
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    setImgSrc("");
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    e.target.value = "";

  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(16 / 9);

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(newCrop);
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  const afterImageCroped = async () => {
    if (previewCanvasRef) {
      setPrev(previewCanvasRef.current?.toDataURL() as string);
      setImgSrc("");
      props.afterImageCrop(
        previewCanvasRef.current?.toDataURL() as string,
        props.id
      );
    }
  };
  const a = (prevImgSrc || (imagePrevUrl ?? imagePrevUrl));

  return (
    <>
      <input
        id={props.id}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        className=" w-full pb-5"
      />
      {(prevImgSrc || (imagePrevUrl ?? imagePrevUrl)) !="" && <img alt="Image Preview" src={prevImgSrc || (imagePrevUrl ?? imagePrevUrl)} className="max-h-56 max-w-56 my-2 pb-5 " />}
      {imgSrc && (
        <Modal
          submitActionLabel="Crop & Upload"
          submitAction={afterImageCroped}
          title="Crop Image"
          isCloseButton
        >
          <div className="App p-2 bg-white h-full ">
            <div className=" relative Crop-Controls text-[#464646] flex flex-col gap-5
             ">
              <div className="flex flex-col gap-2 md:flex-row  md:justify-between md:items-center text-sm justify-center
           ">
                <div className=" flex gap-2 ">
                  <label htmlFor="scale-input">Zoom: </label>
                  <div
                    className=" w-20 flex justify-between rounded-md
            shadow-lg "
                  >
                    <button
                      onClick={(e) =>
                        scale !== 1 &&
                        setScale(Number((scale - 0.1).toFixed(3)))
                      }
                      className="bg-slate-300 px-2 hover:bg-slate-500 rounded-l-md
              border-none w-6"
                    >
                      -
                    </button>
                    <input
                      id="scale-input"
                      type="button"
                      step="0.1"
                      value={scale}
                      disabled={!imgSrc}
                      onChange={(e) => setScale(Number(e.target.value))}
                    />
                    <button
                      onClick={(e) =>
                        setScale(Number((scale + 0.1).toFixed(3)))
                      }
                      className="bg-slate-300 px-2 hover:bg-slate-500 rounded-r-md
            border-none w-6"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex gap-2 ">
                  <label htmlFor="rotate-input">Rotate Image: </label>
                  <div
                    className=" w-20 flex justify-between rounded-md
            shadow-lg "
                  >
                    <button
                      onClick={(e) =>
                        rotate !== 0 &&
                        setRotate(Number((rotate - 1).toFixed(3)))
                      }
                      className="bg-slate-300 px-2 hover:bg-slate-500 rounded-l-md
            border-none w-6"
                    >
                      -
                    </button>
                    <input
                      id="rotate-input"
                      type="button"
                      value={rotate}
                      disabled={!imgSrc}
                      onChange={(e) =>
                        setRotate(
                          Math.min(180, Math.max(-180, Number(e.target.value)))
                        )
                      }
                    />
                    <button
                      onClick={(e) =>
                        setRotate(Number((rotate + 1).toFixed(3)))
                      }
                      className="bg-slate-300 px-2 hover:bg-slate-500 rounded-r-md
              border-none w-6"
                    >
                      +
                    </button>
                  </div>
                </div>
             
              <div>
                {/* <button onClick={handleToggleAspectClick}>
              Toggle aspect {aspect ? 'off' : 'on'}
            </button> */}

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    onClick={handleToggleAspectClick}
                  />
                  <div
                    className="relative w-11 h-6 bg-slate-300 peer-focus:outline-none 
                    rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full 
                    rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                    after:content-[''] after:absolute after:top-[2px] after:start-[2px] 
                    after:bg-white after:border-gray-300 after:border after:rounded-full
                    after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                    peer-checked:bg-slate-500 shadow-lg"
                  ></div>
                  <span className=" text-[#464646] px-2">
                    Toggle aspect {aspect ? "off" : "on"}
                  </span>
                </label>
              </div>
            </div>
            </div>
            <div className="max-h-96 overflow-y-auto">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              // minWidth={400}
              minHeight={100}
              // circularCrop
            >
              <img
               
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            </div>

            {!!completedCrop && (
              <>
                <div className="hidden">
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
export default ImageCropper;
