import { api } from '../api';

export interface SubDomains {
  dnsRecord?: string,
  subDomainSetting?: Object,
  verified?: Boolean
}

export interface DomainAssociation {
  certificate?: string,
  certificateVerificationDNSRecord?: string,
  domainAssociationArn?: string,
  domainName?: string,
  domainStatus?: string,
  enableAutoSubDomain?: string,
  statusReason?: string,
  subDomains?: SubDomains[]
}

export interface App {
    id: string,
    domainAssociation?: DomainAssociation

}
type AppResponse = App[];
export const appApi = api.injectEndpoints({
    endpoints: (build) => ({
      getApp: build.query<AppResponse, string>({
        query: (params) => `app?${params}`,
        providesTags: (_post, _err, id) => [{ type: 'App', id }],
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useGetAppQuery,
    useGetErrorProneQuery,
  } = appApi
  
  export const {
    endpoints: { getApp },
  } = appApi
  