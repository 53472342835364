type Translation = {
    [key: string]: {
        [key: string]: string;
    }
}
const translations:Translation = {
    en: {
      greeting: 'Hello, world!',
      farewell: 'Goodbye, world!',
      welcome: 'Welcome, %s!',
      "School": "School Management %s",
    },
    fr: {
      greeting: 'Bonjour, le monde!',
      farewell: 'Au revoir, le monde!',
      welcome: 'Bienvenue, {name}!',
    },
  };

export const t = (key:string,variables:string[] =  [],lang:string = 'en' ) => {
    
    lang = lang || 'en';
    let translation = key;
    if (translations[lang] && translations[lang][key]) {
        translation = translations[lang][key];
      
      return translation;
    }
    if (variables) {
        /* 
     Object.keys(variables).forEach((variable) => {
         translation = translation.replaceAll(`{${variable}}`, variables[variable]);
       });*/
       variables.forEach((variable) => {
         translation = translation.replace('%s', variable);
       });
   }
    return translation;
  }

  export default t;