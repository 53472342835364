
export const getUserData = ( key:string = "" ) => {
    let userData:any = {};
    if (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== 'undefined') {
        userData = JSON.parse(localStorage.getItem('userData') ?? '{}');
    }
    if(key===""){
        return userData;
    }
    else{
        return userData[key] ?? {};
    }
}
export const logout = () => {
    localStorage.removeItem('userData');
}
export const setCurrentBusiness = ( business:any ) => {
    setUserData({current_business: business});
}
export const getCurrentBusiness = ()  => {
    return getUserData("current_business");
}
export const setUserData = ( user:any, afterSet:any = false) => {
        if (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== 'undefined') {
          var storageData = JSON.parse(localStorage.getItem('userData') ?? '{}');
          Object.keys(user).forEach(function (key) {
            if (storageData[key] !== undefined) {
              if (Array.isArray(storageData[key])) {
                if (Array.isArray(user[key])) {
                  user[key].forEach((obj: any) => {
                    storageData[key].push(obj);
                  });
                } else {
                  storageData[key].push(user[key]);
                }
              } else {
                storageData = { ...storageData, ...user };
              }
            } else {
              storageData = { ...storageData, ...user };
            }
          });
          localStorage.setItem('userData', JSON.stringify(storageData));
          if(afterSet){
            afterSet(storageData);
          }
          
        } else {
          localStorage.setItem('userData', JSON.stringify(user));
          if(afterSet){
            afterSet(storageData);
          }
        }
}