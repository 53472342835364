import React, { useState, useContext, useEffect } from "react";
import { getCurrentBusinessType, getCurrentBusinessId } from "../../../helper/business";
import { publishThemeOnSite } from "../../../helper/themePublish";
import { UserContext } from "../../../utils/UserContext";
import Lock from "./lock.svg";
import Openeye from "../../../images/open-eye.png"
import { useNavigate } from 'react-router-dom'
import { useGetEntitiesQuery, useDeleteEntityMutation, useAddEntityMutation, useUpdateEntityMutation } from "../../../app/services/api/entity";
import { getTimeAsPerTimezone } from "../../../helper/convertedTime";
import { fetchData } from '../../../app/services/apiService';
import {
  addToast
} from '../../toast/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, useGetThemeQuery, useGetThemesQuery } from "../../../app/services/api/theme";
import Modal from "../../modal/Modal";
import Spinner from "../../icons/Spinner";
import { setLoader } from "../../loader/loaderSlice";
import { selectCurrentBusiness } from "../../auth/authSlice";

interface Section {
  image: string;
  created_by: string;
  name: string;
  code: string;
}

interface Pages {
  name: string;
  code: string;
  url: string;
  content: string;
}

interface SavedTheme {
  entity: string;
  status: string;
  code: string;
  type: string;
  name: string,
  id: string,
  image?: string,
  mobile_image?: string,
  custom_entity?: string;
  created_at?: string;
  updated_at?: string;
  amplifyDefaultDomain?: string;
}

type toggalData = {
  [key: string]: boolean;
};




const Themes = () => {
  const dispatch = useDispatch();
  const [savedTheme, setSavedTheme] = useState<SavedTheme[]>([]);
  const [publishTheme, setPublishTheme] = useState<SavedTheme | null>(null);

  const [addEntity] = useAddEntityMutation();
  const [updateEntity] = useUpdateEntityMutation();
  const [deleteEntity] = useDeleteEntityMutation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  //const [jsonData, setJsonData] = useState<Section[] | null>(null);
  const currentBusiness = useSelector(selectCurrentBusiness);
  const businessType = currentBusiness?.type ?? "";
  const businessId = currentBusiness?.id ?? "";
  const { data: jsonData } = useGetThemesQuery("");
  const { data: entities } = useGetEntitiesQuery("entities=theme&businessId=" + currentBusiness?.id);
  const [themeName, setThemeName] = useState('');
  const [renameThemeData, setRenameThemeData] = useState({ id: '', code: '' });

  const currentBusinessTypeThemes = jsonData?.Items?.filter(theme => theme?.business_code === businessType);
  const otherBusinessTypeThemes = jsonData?.Items?.filter(theme => theme?.business_code !== businessType);
  const themes = [
    {
      sectionTitle: 'Pick a theme for your website',
      sectionInfo: 'Choose the design that best represents your brand. You’ll then customize it by adding your content, colors, fonts, and more.',
      themes: currentBusinessTypeThemes
    },
    {
      sectionTitle: 'Try out from this list',
      sectionInfo: 'Choose the design that best represents your brand. You’ll then customize it by adding your content, colors, fonts, and more.',
      themes: otherBusinessTypeThemes
    }
  ];
  /*
  useEffect(() => {
    const loadJsonFile = async () => {
      try {
        const url = `/${businessType}/themes/themes.json`;
        const response = await fetch(url);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error("Error loading JSON file:", error);
      }
    };

    loadJsonFile();
  }, []);
  */
  const formatThemeData = (entity: any) => {

    return {
      entity: entity.entity || "",
      id: entity.id || "",
      name: entity.name || "",
      status: entity.status || "",
      code: entity.code || "",
      type: entity.type || "",
      amplifyDefaultDomain: entity?.amplifyDefaultDomain,
      created_at: getTimeAsPerTimezone(entity.created_at || ""),
      updated_at: getTimeAsPerTimezone(entity.updated_at || ""),
      image: entity.image ?? `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${entity.type}/${entity.code}/image.png`,
      mobile_image: entity.mobile_image
    };
  }
  useEffect(() => {
    if (entities) {
      // Assuming entities is an array of SavedTheme objects or compatible data
      const publishedTheme = entities.find(item => item.status === "Published") as SavedTheme | null;
      if (publishedTheme) {
        setPublishTheme(formatThemeData(publishedTheme));
      }
      else {
        setPublishTheme(null);
      }
      const draftThemeData = entities.filter(item => item.status === "Draft");;
      if (draftThemeData) {
        const transformedData: SavedTheme[] = draftThemeData.map((entity: any) => (
          formatThemeData(entity)
        ));

        setSavedTheme(transformedData);
      }

    }
    dispatch(setLoader(false));
  }, [entities]);

  useEffect(() => {
    const handleElsewhereClick = (e: any) => {
      const dropdownOptions = document.getElementById("themeOptions");
      const publishDropdownOptions = document.getElementById("publishThemeOptions");

      if ((dropdownOptions && !dropdownOptions.contains(e.target)) || (publishDropdownOptions && !publishDropdownOptions.contains(e.target))) {
        const updatedToggle: toggalData = {};
        Object.keys(toggle).forEach(key => {
          updatedToggle[key] = false;
        });
        settoggle(updatedToggle);
      }
    };

    document.addEventListener('mousedown', handleElsewhereClick);

    return () => {
      document.removeEventListener('mousedown', handleElsewhereClick);
    }
  }, []);


  const handleaddlist = (item: any) => {
    let themeObject = {
      entity: `theme.${item.code}`,
      status: "Draft",
      code: item.code,
      type: item?.business_code,
      name: item.name,
      image: item.image,
      mobile_image: item.mobile_image,
      amplifyDefaultDomain: item.amplifyDefaultDomain
    }
    dispatch(setLoader(true));
    addEntity(themeObject);
  }

  const [toggle, settoggle] = useState<toggalData>({});
  const [modal, setModal] = useState<toggalData>({});
  const handleToggle = (elementId: string) => {
    const nodeList = document.querySelectorAll(".theme");
    nodeList.forEach(node => {
      node.classList.add('hidden');
      node.classList.remove('absolute');
    });


    if (elementId) {
      let currentTaggle = toggle[elementId];
      if (typeof currentTaggle === 'undefined') {
        currentTaggle = true;
      } else {
        currentTaggle = !currentTaggle;
      }
      const data = {
        [elementId]: currentTaggle
      };
      const a = {
        ...toggle,
        ...data,
      };

      settoggle(a);
    }

  };

  const handleModal = (elementId: string, elementCode: string) => {
    setRenameThemeData(prevData => ({
      ...prevData,
      id: elementId,
      code: elementCode
    }))
    if (elementId) {
      let currentModalElement = modal[elementId];
      if (typeof currentModalElement === 'undefined') {
        currentModalElement = true;
      } else {
        currentModalElement = !currentModalElement;
      }
      const data = {
        [elementId]: currentModalElement
      };
      const updatedData = {
        ...modal,
        ...data,
      };

      setModal(updatedData);
    }
  };


  const openChooseThemeModal = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const chooseTheme = event.currentTarget.getAttribute('data-code');
    const chooseThemeId = event.currentTarget.getAttribute('data-id');
    const chooseThemeFromType = event.currentTarget.getAttribute('data-type');
    const pageUrl = `/theme/${chooseThemeFromType}/${chooseTheme}/home/${chooseThemeId}`;
    navigate(pageUrl);
  }

  const publishThemeClick = async (themeId: string, themeCode: string, businessType: string) => {
    let themeData = {
      themeId: themeId,
      themeCode: themeCode
    }
    let entityObj = {
      updateEntity: updateEntity,
      addEntity: addEntity
    }
    console.log("publishThemeClick");
    dispatch(setLoader(true));
    let publishResponse = await publishThemeOnSite(businessType, businessId, themeData, entityObj, publishTheme ?? {});
    dispatch(addToast({ success: true, message: publishResponse.message }));
    dispatch(setLoader(false));
  }

  const rename = (themeId: string, themeCode: string) => {
    let themeObject = {
      id: themeId,
      entity: `theme.${themeCode}`,
      name: themeName
    }
    updateEntity(themeObject);
    setThemeName('');
    handleModal(themeId, themeCode);
  }

  const deleteTheme = (themeId: string, themeCode: string) => {
    let themeObject = {
      id: themeId,
      entity: `theme.${themeCode}`
    }
    dispatch(setLoader(true));
    deleteEntity(themeObject);
  }

  return (
    <div className=" bg-[#f1f1f1] w-full flex flex-col justify-center gap-10 font-gtbuddy p-5 md:p-10 ">
      <div className="hidden">
        <h1>Customise Your Website  </h1>
      </div>
      <div className="flex justify-between items-center  ">
        <h2 className=" text-base">Themes</h2>
        <div className="flex items-center gap-1 text-sm px-3 py-1 rounded-lg
            bg-[#f1f1f1] hover:bg-blue-100 border">
          <img className="h-4 w-4" src={Openeye} alt="" />{
            currentBusiness?.amplifyDefaultDomain ?
              <a className=" " rel="noreferrer" target="_blank" href={currentBusiness?.verifiedCustomDomain ? `https://www.${currentBusiness?.verifiedCustomDomain[0]}` : `https://main.${currentBusiness?.amplifyDefaultDomain}`}>View your website</a>
              :
              <a className=" " rel="noreferrer" target="_blank" href={`https://main.${publishTheme?.amplifyDefaultDomain}/demo/${currentBusiness?.id}/${publishTheme?.id}/${publishTheme?.code}`}>Website Demo</a>
          }

        </div>
      </div>
      {publishTheme && <div className="bg-[#ffffff] rounded-lg relative flex flex-col">
        {/* default theme display begain here  */}

        <div className="flex justify-center  items-baseline md:items-baseline md:gap-10  pt-10 ">
          <div className="  z-0 w-52 md:w-96 lg:w-3/5 h-72 sm:h-96 sm:w-96 rounded-t-xl border-x-8 border-[#1a1a1a]
             outline outline-4 outline-stone-800
             border-t-[12px] shadow-xl   ">

            <img className="w-full h-full  object-cover object-top" src={publishTheme.image} alt="Default_Themename" />
          </div>
          <div className="z-0   md:w-48 md:h-96 -ml-14 md:m-0 w-32 h-64 rounded-xl border-x-8 border-[#030303]
             outline outline-4 outline-stone-800
             border-y-[16px] shadow-xl ">
            <img className=" w-full h-full object-cover object-left-top" src={publishTheme.mobile_image ?? publishTheme.image} alt="Default_Themename" />
          </div>

        </div>
        {/* default theme dispalay end here */}

        {/* pick a plan began here */}

        <div className=" z-10  flex flex-col md:flex-row  gap-2 md:gap-0 justify-between items-center bg-[#fff1e3] w-full  p-5 text-xs flex-wrap md:flex-nowrap text-[#303030] h-14 ">
          <div className="flex justify-center items-center gap-1" >
            {/* <img className="h-5" src={Lock} alt=""/> */}
            <p className="text-sm "></p>
          </div>
          <div className="flex text-sm items-center  gap-6">
            <p></p>
            {/* <button className="bg-stone-50 rounded-lg py-1 px-4 shadow-lg
                 border border-stone-300 font-semibold"></button> */}
          </div>
        </div>
        {/* pick a plan end here */}
        <div className=" flex flex-col lg:flex-row gap-6 md:gap-0 justify-between items-center p-5 bg-[#ffffff] rounded-b-lg text-sm">
          <div className="flex items-center gap-3 ">
            <div className="h-20 w-20 rounded-lg border border-stone-300">
              <img
                className="h-full w-full rounded-lg object-cover object-left-top"
                src={publishTheme.image}
                alt="Theme"
                style={{ imageRendering: 'auto' }} // This ensures the image is rendered as smoothly as possible
              />
            </div>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center " >
                <h3 className="font-bold">{publishTheme && publishTheme.name}</h3>
                <p className=" text-xs  rounded-lg bg-teal-300 px-3">{publishTheme ? publishTheme.status : ""}</p>
              </div>
              <p>Updated At:{publishTheme && publishTheme.updated_at}</p>
            </div>

          </div>

          <div className="flex gap-3 items-center justify-center relative">
            <div className="flex gap-0.5 px-2 py-3 bg-stone-50 border border-stone-300 rounded-md shadow-lg cursor-pointer" onClick={() =>
              handleToggle(publishTheme.id)}>
              <div className="h-1 w-1 rounded-full bg-stone-800"></div>
              <div className="h-1 w-1 rounded-full bg-stone-800"></div>
              <div className="h-1 w-1 rounded-full bg-stone-800"></div>
            </div>
            {toggle[publishTheme.id] && <div className="  z-50 absolute w-48 rounded-lg bg-[#ffffff] top-8 right-32
                      border border-stone-200 text-[#303030] hover:cursor-pointer font-gtbuddy text-sm" id="publishThemeOptions">
              <div className="flex flex-col border border-stone-200 divide-y-2 divide-stone-200 rounded-md">
                <a className="px-2 py-3 hover:bg-[#f1f1f1] text-left" rel="noreferrer" target="_blank"
                  href={`https://main.${publishTheme?.amplifyDefaultDomain}/demo/${currentBusiness?.id}/${publishTheme?.id}/${publishTheme?.code}`}
                >Preview</a>

                <button className="px-2 py-3 hover:bg-[#f1f1f1] text-left" onClick={() => { handleToggle(publishTheme.id); handleModal(publishTheme.id, publishTheme.code) }}>Rename</button>
              </div>
            </div>}
            <button className="bg-stone-50 rounded-lg py-1 px-4 shadow-lg
                 border border-stone-300 font-semiboldd" onClick={() => publishThemeClick(publishTheme.id, publishTheme.code, publishTheme.type)}>Publish</button>
            <button data-code={publishTheme.code} data-id={publishTheme.id} data-type={publishTheme.type} className="px-4 py-1 rounded-lg bg-stone-800 shadow-xl text-stone-100 font-semibold hover:bg-stone-900" onClick={openChooseThemeModal}>Customize</button>
          </div>

        </div>

      </div>}
      {/* <p> add theme and publish theme here  start here</p> */}
      <div className="bg-[#ffffff] rounded-lg" id="theme">
        <div className="flex flex-col md:flex-row gap-2 md:gap-0  justify-between items-center border-b-2 border-stone-200  p-5 text-sm">
          <div>
            <p className=" font-bold ">Theme library</p>
            <p >These themes are only visible to you. You can switch to another theme by publishing it to your store.</p>
          </div>
        </div>
        {/* all theame will be add here */}
        {savedTheme && savedTheme.length > 0 ? (savedTheme.map((theme: any, i) =>
          theme?.id && <div key={theme.id} className="flex flex-col md:flex-row gap-2 md:gap-0  justify-between items-center p-5 bg-[#ffffff] rounded-b-lg text-sm">
            <div className="flex items-center gap-1 ">
              <div className="h-20 w-20 rounded-lg border border-stone-300" >
                <img className="h-full w-full object-cover object-right-top"
                  src={theme.image
                  } alt="" />
              </div>

              <div className="flex flex-col">
                <div className="flex gap-2 items-center " >
                  <h3 className="font-bold">{theme.name}</h3>
                  <p className="text-xs rounded-lg bg-teal-300 px-3">{theme.status}</p>
                </div>
                <p className="">Updated:{theme.updated_at}</p>
              </div>

            </div>
            <div className="flex gap-3 items-center justify-center relative"
            >
              <div key={theme.id} className="flex gap-0.5 px-2 py-3 bg-stone-50 border 
                  border-stone-300 rounded-md shadow-lg cursor-pointer" onClick={() =>
                  handleToggle(theme.id)}>
                <div className="h-1 w-1 rounded-full bg-stone-800"></div>
                <div className="h-1 w-1 rounded-full bg-stone-800"></div>
                <div className="h-1 w-1 rounded-full bg-stone-800"></div>
              </div>
              {toggle[theme.id] && <div className=" theme z-50 absolute w-48 rounded-lg bg-[#ffffff] top-8 right-56
                      border border-stone-200 hover:cursor-pointer font-gtbuddy text-sm " id="themeOptions">
                <div className="flex flex-col border border-stone-200 divide-y-2 divide-stone-200 rounded-md">
                  <a className="px-2 py-3 text-left hover:bg-[#f1f1f1]" target="_blank"
                    rel="noreferrer" href={`https://main.${theme?.amplifyDefaultDomain}/demo/${currentBusiness?.id}/${theme?.id}/${theme?.code}`}
                  >Preview</a>
                  <button className="px-2 py-3 text-left hover:bg-[#f1f1f1]" onClick={() => { handleToggle(theme.id); handleModal(theme.id, theme.code) }}>Rename</button>
                  <button className="px-2 py-3 text-left hover:bg-[#f1f1f1]" onClick={() => deleteTheme(theme.id, theme.code)} >Remove</button>
                </div>
              </div>
              }
              <button className="bg-stone-50 rounded-lg py-1 px-4 shadow-lg
                 border border-stone-300 font-semiboldd" onClick={() => publishThemeClick(theme.id, theme.code, theme.type)}>Publish</button>
              <button data-code={theme.code} data-id={theme.id} data-type={theme.type} className="bg-stone-50 rounded-lg py-1 px-4 shadow-lg
                 border border-stone-300 font-semiboldd" onClick={openChooseThemeModal}>Customize</button>
            </div>
          </div>)) : <p className=" text-center p-10 text-xs bg-stone-50 ">Try out new theme,work on seasonal version, or test changes to your current theme</p>}
      </div>

      {
        modal[renameThemeData?.id] &&
        <Modal
          submitAction={() => rename(renameThemeData?.id, renameThemeData?.code)}
          submitActionLabel="Save"
          title={"Rename Theme"}
          submitActionDisabled={!themeName}
          onClick={() => handleModal(renameThemeData?.id, renameThemeData?.code)}
        >
          <div className="block my-4">
            <div className="col-span-2">
              <input
                type="text"
                name="name"
                id="name"
                value={themeName}
                onChange={(e) => setThemeName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Enter Theme Name"
              />
            </div>
          </div>
        </Modal>
      }

      {/* Main theme part start here */}
      {
        themes.map((themeType, index) => (
          <div key={index} className=" border border-stone-300 rounded-lg  bg-[#ffffff]">
            <div className="p-5 ">
              <h3 className="font-bold text-xl">
                {themeType?.sectionTitle}
              </h3>
              <p className="text-sm ">
                {themeType?.sectionInfo}
              </p>
            </div>
            <div className="flex justify-start items-center w-full flex-wrap gap-5 p-5   ">
              {themeType ? (
                themeType?.themes?.filter((item) => item.amplifyDefaultDomain && item.amplifyDefaultDomain !== '').map((item: Theme) => (
                  <section key={item.id} className="relative pt-6 bg-stone-50 rounded-xl shadow-lg
                    border border-stone-200">
                    {/* Image on the left */}
                    <div className=" w-72 h-72 ">
                      <img
                        className=" h-full w-full object-cover object-left-top"
                        src={item.image}
                        alt={"themeImage"}
                      />
                    </div>
                    <div className="flex justify-between   px-3  border-t-2 border-stone-200 py-3">
                      {/* Content in the center */}
                      <div>
                        <h2 className=" font-bold "  >
                          {item.name}
                        </h2>
                        <p className="text-sm">
                          {item.created_by}
                        </p>
                      </div>

                      {/* Buttons on the bottom right and top left corners */}
                      <div>
                        <button className="bg-stone-50 rounded-lg py-1 px-4 shadow-lg
                    border border-stone-300 text-sm"
                          onClick={(e) => handleaddlist(item)}>
                          Add
                        </button>
                      </div>
                    </div>
                  </section>
                ))
              ) : (
                // <p>Loading JSON data...</p>
                <Spinner />
              )}
            </div>
          </div>
        ))
      }
    </div>
  );
}
export default Themes;
