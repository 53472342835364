import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../app/services/apiService';
import { useNavigate } from 'react-router-dom'
import {
    addToast
} from '../../toast/toastSlice';
import Toast from '../../toast/messages';
import { UserContext } from '../../../utils/UserContext';
import { publishThemeOnSite } from "../../../helper/themePublish";
import { useAddEntityMutation, useUpdateEntityMutation } from "../../../app/services/api/entity";
import { setCurrentBusiness } from '../../auth/authSlice'
import { themeApi } from '../../../app/services/api/theme';
import { setLoader } from '../../loader/loaderSlice';
import PageLoader from '../../loader/PageLoader';
import GlobalConstants from '../../../helper/GlobalConstants';

function Business() {
    const [addEntity] = useAddEntityMutation();
    const [updateEntity] = useUpdateEntityMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [businessName, setBusinessName] = useState('');
    const [validBusinessFormat, setValidBusinessFormat] = useState(true);
    const [businessType, setBusinessType] = useState('education');
    const [availableBusiness, setAvailableBusiness] = useState(false);
    const [getTheme] = themeApi.endpoints.getTheme.useLazyQuery();

    var isBusinessAvail = true;
    const { user, setDiUser } = useContext(UserContext);
    useEffect(() => {
        dispatch(setLoader(false));
    }, []);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(setLoader(true));
        await registerBusiness();
    }
    const validateBusinessName = (name: string): boolean => {
        const nameRegex = /^[a-zA-Z0-9\-]+$/;
        return nameRegex.test(name) && name.length >= 3 && name.length <= 50;
    };


    async function registerBusiness() {
        if (!validateBusinessName(businessName)) {
            setValidBusinessFormat(false);
            return true;
        }
        await checkAvailable();
        if (!isBusinessAvail) {
            let themeData = {
                themeCode: 'basic',
                themeName: "Basic"
            }
            let theme = await getTheme("id=" + encodeURIComponent(businessType + "#" + themeData.themeCode));
            if (theme.isSuccess && theme?.data) {
                themeData = { ...themeData, ...theme.data };
            }
            else {
                console.log('Default theme not found.')
                return false;
            }
            const createdBusiness = await postData('business', { name: businessName, type: businessType });

            dispatch(addToast({ type: "success", message: "Business Created Successfully." }));
            setDiUser({ user_data: [{ business_name: businessName, business_code: createdBusiness.code }] });

            let entityObj = {
                updateEntity: updateEntity,
                addEntity: addEntity
            }

            dispatch(setCurrentBusiness(createdBusiness));

            //setDiUser({current_business :createdBusiness});
            let response = await publishThemeOnSite(businessType, createdBusiness.id, themeData, entityObj, {});
            console.log(response, 'buresponse')
            navigate('/panel');
            dispatch(setLoader(false));


        }

    }
    async function checkAvailable() {
        const isBusinessAvailbale = await fetchData('business/check?name=' + businessName + '&type=' + businessType);
        setAvailableBusiness(isBusinessAvailbale.success);
        isBusinessAvail = isBusinessAvailbale.success;
    }


    return (
        <div className="bg-[#99defd] min-h-screen overflow-hidden flex justify-center items-center relative py-10 px-10">
            <div className="absolute top-3 right-3 ">
                <Toast></Toast>
                <PageLoader />
            </div>
            <div className="  flex flex-col  justify-center gap-4 px-10 py-5  rounded-xl  font-gtbuddy bg-white lg:w-1/2 ">
                <form className="flex flex-col gap-4" onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST">
                    <div className="flex flex-col">
                        <label className="text-neutral-800 font-bold">
                            Business Name &#x2A;
                        </label>
                        <input
                            className="p-3 rounded-xl focus:outline-none focus:border-blue-500 border border-neutral-950 text-neutral-700"
                            type="text"
                            value={businessName}
                            id="business"
                            placeholder='Enter your business name (e.g., SmithsBakery123, Acme-Solutions)'
                            required
                            onChange={(e) => {
                                setBusinessName(e.target.value)
                                if (e.target.value.length)
                                    setValidBusinessFormat(validateBusinessName(e.target.value));
                                else
                                    setValidBusinessFormat(true);

                            }}
                        />
                        <div className=" text-sm">
                            {
                                !validBusinessFormat ? <span className="text-red-700 w-1/2">Your business name can only contain letters, digits, and hyphens. Please avoid using spaces or other special characters. Ensure your business name is between 3 and 50 characters long.
                                </span> : ''
                            }
                        </div>
                        <div className=" text-sm">
                            {

                                availableBusiness ? <span className="text-red-700">Business name is not available as per selected business type </span> : ''
                            }
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-neutral-800 font-bold" >
                            Select your business type &#x2A;
                        </label>
                        <div className="p-3  flex  flex-wrap items-center justify-center md:justify-start gap-5 mx-auto">
                            {
                                GlobalConstants.BUSINESS_TYPES.map((business: any) => {
                                    console.log("business icon" ,business.icon)
                                    return (
                                        <button
                                            key={business.value}
                                            type="button"
                                            value={business.value}
                                            className={` relative flex flex-col items-center shadow-lg rounded-xl border-2 w-36 h-40 bg-white 
                                        ${businessType === business.value ? "outline outline-2 outline-offset-1 ... outline-blue-500 " : " "}`}
                                            onClick={(e) => setBusinessType((e.currentTarget as HTMLButtonElement).value)}
                                            disabled={!business.isAvailable}
                                        >
                                            {
                                                businessType === business.value &&
                                                <div className=" w-full relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="blue" className={`w-6 h-6 absolute  top-1 right-2 opacity-1`}>
                                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                            }
                                            <div className=" absolute flex flex-col justify-between h-36  pt-10 items-center">
                                                {business.icon}
                                                {business.name}
                                            </div>
                                            {!business.isAvailable && <div className="absolute z-20 top-0 left-0 right-0 bottom-0 bg-white opacity-50 rounded-lg "><p className="text-black font-bold mt-3 text-xl opacity-60 ">  Coming Soon </p></div>}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="w-full sticky right-5 bottom-1 text-center md:text-end ">
                        <button className={`   px-6 bg-[#303030] py-3 rounded-xl text-white w-56 ${businessName.trim().length >= 3 && businessType.length > 0 ? "opacity-100" : " opacity-30 "}  `} disabled={businessName.trim().length === 0 && businessType.length === 0}>
                            Proceed  &amp; Save
                        </button>
                    </div>
                </form>
                <div className=" text-sm">
                    <span>By proceeding, you agree to the</span> <span className="text-blue-700"><a href="https://www.gtbuddy.com/termNcondition.html" target="_blank"> Terms and Conditions</a></span> <span>and</span> <span className="text-blue-700"><a href="https://www.gtbuddy.com/privacypolicy.html" target="_blank">Privacy Policy</a></span>
                </div>
            </div>
        </div>
    );
}
export default Business;