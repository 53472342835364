import React, { useState , useEffect} from 'react';

const countryData = [
  { name: 'United States', code: 'US', extension: '+1',format:/^[6-9][0-9]{9}$/},
  { name: 'United Kingdom', code: 'UK', extension: '+44',format:/^[6-9][0-9]{9}$/ },
  { name: 'India', code: 'IN', extension: '+91',format:/^[6-9][0-9]{9}$/ },
  // Add more countries as needed
];

const MobileNumberInput = (props:any) => {
  const countryCode = props.CountryCode;
  const selected = countryData.find((country) => country.code === countryCode);
  const [selectedCountry, setSelectedCountry] = useState(selected!);
  const mobileNumberRegex = selectedCountry.format;
  const ext = selectedCountry.extension

 
  const [mobileNumber, setMobileNumber] = useState('');
  const handleMobileNumberChange = (e:any) => {
    const formattedMobileNumber = e.target.value.replace(/\D/g, '');
    setMobileNumber(formattedMobileNumber);
    var returnVal = {validation:false,message:'Invalid Phone Number'};
    if(mobileNumberRegex.test(formattedMobileNumber))
    {
      returnVal.validation = true;
      returnVal.message = "";
    }
    props.onChange(returnVal);
    props.MobileValues({phone_number:formattedMobileNumber,ext:ext});
  };


  return (
    <>
          <label htmlFor="mobileNumber" className="text-neutral-800">
            Mobile Number:
          </label>
          <div className="relative">
              <span className="top-1 left-0 m-2 absolute ">
                {selectedCountry.extension || ''}
              </span>
              <input
                type="hidden"
                id="mobileNumber_ext"
                name="mobileNumber_ext"
                value={selectedCountry.extension}
                required
                className="p-3 pl-10 rounded-xl focus:outline-none focus:border-blue-500 border w-full border-neutral-950 text-neutral-700 z-10"
              />
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Enter your mobile number"
                value={mobileNumber}
                required
                onChange={handleMobileNumberChange}
                className="p-3 pl-10 rounded-xl focus:outline-none focus:border-blue-500 border w-full border-neutral-950 text-neutral-700 z-10"
              />
        </div>  
    </>
  );
};

export default MobileNumberInput;
