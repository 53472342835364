import { createSlice } from '@reduxjs/toolkit'

interface ToastMessage {
  message: string;
  type: string
}
interface ToastState extends Array<ToastMessage> { }

const initialState: ToastState = [];

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (
      state, action
    ) => {
      console.log('updated state toast');
      state.pop();
      let m: ToastMessage = action.payload;
      state.push(m);

    },
    clearToast: (
      state
    ) => {
      state.pop();

    },
  },
})
export const { addToast, clearToast } = slice.actions
export const selectToast = (state: any) => state.toast


export default slice.reducer