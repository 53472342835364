import { convertFormDataToObject, Crud } from "../crud/Crud";
import { useGetThemesQuery, themeApi, useDeleteThemeMutation, useAddThemeMutation, useUpdateThemeMutation, usePublishThemeMutation } from "../../app/services/api/theme";
import { useEffect, useState } from "react";
import { UpDown } from "../icons/UpDown";
import { CollapsibleContent } from "../forms/CollapsibleContent";
import Input from "./fields/input";
import Text from "../forms/form/Fields";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { s3Api } from "../../app/services/api/s3";
import { useDispatch } from "react-redux";
import { addToast } from "../toast/toastSlice";
import Modal from "../modal/Modal";
import t from "../../app/services/translation";
import GlobalConstants from "../../helper/GlobalConstants";
import { setLoader } from "../loader/loaderSlice";

const loadJSONSync = (filePath: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath + "?_=" + (new Date().getTime()), false); // synchronous request
    xhr.send(null);
    if (xhr.status === 200) {
        return xhr.responseText;
    } else {
        console.error('Error loading JSON:', xhr.status);
        return null;
    }
}

export const FormExtraLinks = (props: { form: any, formId: string }) => {
    const dispatch = useDispatch();
    const [publishTheme] = usePublishThemeMutation();
    const { id } = useParams();
    let [searchParams] = useSearchParams();
    let entityCode = searchParams.get('entity');
    let formId = props.formId;
    return <Link
        to={'./'}
        onClick={(e) => {
            e.preventDefault();
            let form = document.getElementById(formId);
            console.log(formId);
            if (form != null) {
                let data = new FormData(form as HTMLFormElement);
                const formData = Object.fromEntries(data);
                console.log(formData);
                if (id && entityCode) {
                    formData.id = id;
                    formData.entity = entityCode;
                    try {
                        dispatch(setLoader(false));
                        publishTheme(convertFormDataToObject(formData)).unwrap();
                        dispatch(addToast({ type: "success", message: "Theme published successfully." }));
                        dispatch(setLoader(false));
                    }
                    catch (e) {
                        console.log(e);
                        dispatch(addToast({ type: "error", message: "Unable to publish theme." }));

                        dispatch(setLoader(false));
                    }
                    //  console.log('Dispatch save event');

                }
            }

        }}
        className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 cursor-pointer">
        Publish
    </Link>
}
export const FormExtraFields = (props: any) => {
    const [trigger] = s3Api.endpoints.getSignedUrl.useLazyQuery();
    let triggerTimestamp = 0;
    type pageContentType = {
        [key: string]: {
            content: string,
            updated: boolean
        }
    }
    const [addNewPage, setAddNewPage] = useState(false);
    const [newPageCode, setNewPageCode] = useState("");
    let defaultContent: pageContentType = {};

    const [pageContent, setPageContent] = useState(defaultContent);
    let { id } = useParams();
    let values: string[] = props.form.fields.pages.value ?? [];
    let code: string = props.form.fields.code.value ?? "";
    let business_code: string = props.form.fields.business_code.value ?? "";
    const s3BaseUrl = process.env.REACT_APP_THEME_BUCKET_URL ?? '';
    const s3Bucket = process.env.REACT_APP_S3_BUCKET ?? '';
    let s3Directory = "themes/" + business_code + "/" + code;
    console.log(values);
    console.log(props.form);
    console.log(props.form.fields.pages.value);
    const dispatch = useDispatch();
    props.form.eventTarget.addEventListener("formSave", function () {

        document.getElementById('save-pages')?.click();
    });
    const cancelAction = () => {
        console.log('setting add new page ');
        setAddNewPage(false);
    }
    useEffect(() => {
        let pagesCont: pageContentType = {};
        triggerTimestamp = Date.now();
        pages.forEach((page) => {

            let fileName = page + ".json";
            let s3Url = s3BaseUrl + s3Directory + '/' + fileName;
            let data = loadJSONSync(s3Url);
            pagesCont[page] = { content: "{}", updated: false };
            pagesCont[page].content = data ?? "";
            pagesCont[page].updated = false;
        });
        //console.log('Loaded page content', pagesCont);
        setPageContent(pagesCont);
        console.log('hook save event');
        props.form.eventTarget.addEventListener("formSave", function () {
            console.log('form save event worked');
            console.log(document.getElementById('save-pages'));
            document.getElementById('save-pages')?.click();


        });

    }, []);
    const [pages, setPages] = useState(values);
    const [updatedPages, setUpdatedPages] = useState([]);
    const addPage = (pageCode: string) => {
        //let pageCode = "page" + pages.length;
        setPages([...pages, pageCode]);
        setPageContent({ ...pageContent, [pageCode]: { content: "{}", updated: true } })
    }

    const savePages = () => {
        if ((Date.now() - triggerTimestamp) < 2000) {
            return true;
        }
        triggerTimestamp = Date.now();
        Object.entries(pageContent).forEach(([page, { content, updated }]) => {
            if (!updated) {
                return;
            }
            let fileName = s3Directory + "/" + page + ".json";
            let queryString = "file_name=" + fileName;
            queryString += "&bucket_name=" + s3Bucket;
            let tagging = 'public=yes';
            trigger(queryString).then(data => {
                let url: any = '';
                //let s3key: any = s3Directory + '/' + fileName;
                url = data?.data?.url;
                const response = fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/*',
                        'x-amz-tagging': tagging
                    },
                    body: prettyJson(pageContent[page].content) ?? "{}"
                }).then(data => data.text())
                    .then((data) => {
                        dispatch(addToast({ type: "success", message: "Page : " + page + "  saved successfully." }));

                    }).catch((e) => {
                        dispatch(addToast({ type: "error", message: "Page : " + page + " Msg:" + e.message() }));
                    });

            });

        });

    }
    const prettyJson = (json: string) => {
        try {
            return JSON.stringify(JSON.parse(json), null, 4);
        }
        catch (e) {
            return json;
        }

    }

    return <div>
        {

            id && pages.map((page) => {
                return <CollapsibleContent
                    key={page}
                    heading={<Text
                        name={"pages." + page}
                        id={page}
                        readOnly={true}
                        placeholder="Enter Page Code" value={page} />}
                >
                    <textarea onChange={e => { setPageContent({ ...pageContent, [page]: { content: e.target.value, updated: true } }); }} value={pageContent[page]?.content} className="border border-gray-400 p-2 rounded-lg w-full h-96"></textarea>
                </CollapsibleContent>
            })
        }
        {
            id && <div className="p-4 flex justify-between items-center relative">

                <button key="add-new-page" id="add-new-page" className="text-blue-500 absolute right-1 z-10" type="button" onClick={() => { setAddNewPage(true) }}>
                    Add New Page
                </button>
                {
                    addNewPage && <Modal title={t("Add new page")}
                        cancelAction={cancelAction}

                        submitAction={() => {
                            if (newPageCode !== "") {
                                addPage(newPageCode);
                            }
                            setAddNewPage(false);
                            setNewPageCode("");
                        }}
                        submitActionLabel={t("Add page")}
                    >
                        <Text id="new-page" value={""} setValue={(value: string) => {
                            setNewPageCode(value);
                        }} placeholder={t("Enter Page Code")} />
                    </Modal>
                }
                <button key="save-page" className="text-blue-500  z-1" id="save-pages" type="button" onClick={savePages}>
                    Save Pages Only
                </button>

            </div>
        }

    </div >
}
export const BusinessWiseThemes = () => {
    const navigate = useNavigate();
    return <div className="p-3  flex  flex-wrap items-center justify-center md:justify-start gap-5 mx-auto">
        {
            GlobalConstants.BUSINESS_TYPES.map((business: any) => {
                return (
                    <button
                        key={business.value}
                        type="button"
                        value={business.value}
                        className={` relative flex flex-col items-center shadow-lg rounded-xl border-2 w-36 h-40 bg-white 
                ${GlobalConstants.BUSINESS_TYPES === business.value ? "outline outline-2 outline-offset-1 ... outline-blue-500 " : " "}`}
                        onClick={(e) => { navigate("./business/" + (e.currentTarget as HTMLButtonElement).value) }}
                        disabled={!business.isAvailable}
                    >
                        <div className=" absolute flex flex-col justify-between h-36  pt-10 items-center">
                            {business.icon}
                            {business.name}
                        </div>
                        {!business.isAvailable && <div className="absolute z-20 top-0 left-0 right-0 bottom-0 bg-white opacity-50 rounded-lg "><p className="text-black font-bold mt-3 text-xl opacity-60 ">  Coming Soon </p></div>}
                    </button>
                )
            })
        }
    </div>;
}
export const BaseTheme = () => {
    let { businessCode } = useParams();
    const { data: entities, isLoading } = useGetThemesQuery(businessCode ? "business_code=" + businessCode : "");
    const [deleteEntity/*, { isLoading: isDeleting }*/] = useDeleteThemeMutation();
    const [addEntity] = useAddThemeMutation();
    const [updateEntity] = useUpdateThemeMutation();
    let form = {
        title: "Base Themes",
        fields: {

            business_code: {
                title: "Business",
                code: "business_code",
                type: "select",
                value: businessCode,
                options: [
                    {
                        value: "education",
                        label: "Education"
                    },
                    {
                        value: "food",
                        label: "Food"
                    },
                    {
                        value: "pharmacy",
                        label: "Pharmacy"
                    }

                ]
            },
            image: {
                title: "Image",
                code: "image",
                type: "s3_image",
                s3Bucket: process.env.REACT_APP_S3_BUCKET,
                s3Directory: "themes",
                s3BaseUrl: process.env.REACT_APP_THEME_BUCKET_URL,
                isTemp: true
            },
            mobile_image: {
                title: "Mobile Image",
                code: "mobile_image",
                type: "s3_image",
                s3Bucket: process.env.REACT_APP_S3_BUCKET,
                s3Directory: "themes",
                s3BaseUrl: process.env.REACT_APP_THEME_BUCKET_URL,
                isTemp: true
            },

        }

    };
    return isLoading ? <></> : (
        <Crud title="Theme"
            gridRoute="/panel/manage/theme"
            schema={GlobalConstants.THEME_SCHEMA}
            data={isLoading ? [] : entities?.Items}
            allowedList={{ list: true, add: true, view: true, edit: true, delete: true }}
            actions={[]}
            form={form}
            apiEndPoints={themeApi.endpoints}
            deleteEntity={deleteEntity}
            addEntity={addEntity}
            updateEntity={updateEntity}
            FormExtraFields={FormExtraFields}
            FormExtraLinks={FormExtraLinks}
        /*massAction={{ 'selectedValues': { '3a85f5be-e65d-4fb3-99e5-a27963bd2882': '3a85f5be-e65d-4fb3-99e5-a27963bd2882' } }}*/
        />
    );
}