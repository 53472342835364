import React, { useEffect } from "react";
import { useGetBusinessDetailsQuery } from "../../../app/services/api/business";
import { useParams } from "react-router-dom";
import PageLoader from "../../loader/PageLoader";
import { setLoader } from "../../loader/loaderSlice";
import { useDispatch } from "react-redux";

const BusinessDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    console.log("ID from params: ", id);
    const { data, isLoading } = useGetBusinessDetailsQuery(`id=${id}` || '');

    useEffect(() => {
        if(isLoading)
            dispatch(setLoader(true));
        else    
            dispatch(setLoader(false));
    }, [isLoading]);

    const businessDetails = data?.items[0];
    const appInfo = data?.appDetails?.app;
    const domainInfo = data?.domainDetails;
    const deploymentInfo = data?.jobDetails?.job;
    const jobSummary = deploymentInfo?.summary;
    const deploymentStages = deploymentInfo?.steps;

    const convertToIST = (timestamp: string) => {
        if(!timestamp)
            return "00:00:00";
        const utcDate = new Date(timestamp);
        const offset = 5.5*60*60*1000;
        const istDate = new Date(utcDate.getTime() + offset);

        const year = istDate.getFullYear();
        const month = String(istDate.getMonth()+1).padStart(2,'0');
        const day = String(istDate.getDate()).padStart(2,'0');

        const hours = String(istDate.getDate()).padStart(2,'0');
        const min = String(istDate.getMinutes()).padStart(2,'0');
        const sec = String(istDate.getSeconds()).padStart(2,'0');

        const date = `${day}-${month}-${year}`;
        const time = `${hours}:${min}:${sec} IST`;
        return date + " " + time;
    }

    return (
        isLoading ? <PageLoader /> :
        <div className="flex flex-col m-8 gap-y-16 justify-center py-8">
            <h1 className="font-bold text-3xl">Business Details</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className="border-2 p-2 rounded-md">Account ID: <span className="text-gray-700">{businessDetails?.accountId}</span></div>
                <div className="border-2 p-2 rounded-md">Amplify App Id: <span className="text-gray-700">{businessDetails?.amplifyAppId}</span></div>
                <div className="border-2 p-2 rounded-md">Account Default Domain: <span className="text-gray-700">{businessDetails?.amplifyDefaultDomain}</span></div>
                <div className="border-2 p-2 rounded-md">Code: <span className="text-gray-700">{businessDetails?.code}</span></div>
                <div className="border-2 p-2 rounded-md">Business ID: <span className="text-gray-700">{businessDetails?.id}</span></div>
                <div className="border-2 p-2 rounded-md">Business Name: <span className="text-gray-700">{businessDetails?.name}</span></div>
                <div className="border-2 p-2 rounded-md">PK: <span className="text-gray-700">{businessDetails?.pk}</span></div>
                <div className="border-2 p-2 rounded-md">SK: <span className="text-gray-700">{businessDetails?.sk}</span></div>
                <div className="border-2 p-2 rounded-md">Business Type: <span className="text-gray-700">{businessDetails?.type}</span></div>
                <div className="border-2 p-2 rounded-md">Username: <span className="text-gray-700">{businessDetails?.username}</span></div>
                <div className="border-2 p-2 rounded-md">Custom Domain: <span className="text-gray-700">{businessDetails?.customDomain}</span></div>
                <div className="border-2 p-2 rounded-md">Verified Custom Domain: <span className="text-gray-700">{businessDetails?.verifiedCustomDomain}</span></div>
            </div>
            {
                appInfo &&
                <div className="flex flex-col">
                    <h1 className="font-bold text-2xl pb-4">App Information</h1>
                    <div className="grid grid-cols-1 gap-2">
                        <div className="border-2 p-2 rounded-md">App Name: <span className="text-gray-700">{appInfo?.name}</span></div>
                        <div className="border-2 p-2 rounded-md">Repository URL: <span className="text-gray-700">{appInfo?.repository}</span></div>
                        <div className="border-2 p-2 rounded-md">Default Domain: <span className="text-gray-700">{appInfo?.defaultDomain}</span></div>
                        <div className="border-2 p-2 rounded-md">App Arn: <span className="text-gray-700">{appInfo?.appArn}</span></div>
                        <div className="border-2 p-2 rounded-md">Build Specifications: <pre className="text-gray-700">{appInfo?.buildSpec}</pre></div>
                        {
                            appInfo?.environmentVariables &&
                            <div className="flex flex-col border-2 p-2 rounded-md gap-y-2">
                                Environment Variables: 
                                <div className="italic">Base Organization: <span className="bg-slate-200 p-1 rounded-lg">{appInfo?.environmentVariables?.BASE_ORGANIZATION}</span></div>
                                <div className="italic">Base Repository: <span className="bg-slate-200 p-1 rounded-lg">{appInfo?.environmentVariables?.BASE_REPOSITORY}</span></div>
                                <div className="italic">Deploy Key: <span className="bg-slate-200 p-1 rounded-lg">{appInfo?.environmentVariables?.DEPLOY_KEY}</span></div>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                domainInfo &&
                <div className="flex flex-col">
                    <h1 className="font-bold text-2xl border-b-2 pb-2">Domain Information</h1>
                    {
                        domainInfo?.map((domain: any, index: any) => {
                            let certificateVerificationDNSRecord = domain?.domainAssociation?.certificateVerificationDNSRecord?.split(' ');
                            let hostName, type, url;
                            if(certificateVerificationDNSRecord) {
                                hostName = certificateVerificationDNSRecord[0];
                                type = certificateVerificationDNSRecord[1];
                                url = certificateVerificationDNSRecord[2];
                            }
                            return (
                                <div className="flex flex-col gap-y-8 border border-gray-500 p-4 rounded-md" key={index}>
                                <div className="flex flex-row gap-x-2">
                                    <h2 className="font-bold text-gray-500">Custom domain: <span className="text-black">{domain?.domainAssociation?.domainName}</span></h2>
                                    <p className="font-bold text-sm text-rose-500 border border-rose-500 rounded-full px-2">{index+1}</p>
                                </div>
                                <div className="flex flex-row gap-32">
                                    <div className="flex-col">
                                        <p className="text-gray-500">Status</p>
                                        <p className="text-sm">{domain?.domainAssociation?.domainStatus}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-4">
                                    <h2 className="font-bold">Add a CNAME with these values</h2>
                                    <div className="flex flex-col gap-x-2 gap-y-4 xl:flex-row xl:gap-y-0 justify-between">
                                        <div className="flex-col">
                                            <p className="text-gray-500">Hostname</p>
                                            <p className="text-sm">{hostName}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-gray-500">Type</p>
                                            <p className="text-sm">{type}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-gray-500">Data/URL</p>
                                            <p className="text-sm">{url}</p>
                                        </div>
                                    </div>
                                    <h2 className="font-bold">Configure these records to point your subdomains to the Amplify domain</h2>
                                    {
                                        domain?.domainAssociation?.subDomains?.map((subDomain: any, index: any) => {
                                            let subDomainInfo = subDomain?.dnsRecord?.split(' ');
                                            let hostname, type, url;
                                            if(subDomainInfo) {
                                                hostname = subDomainInfo[0];
                                                type = subDomainInfo[1];
                                                url = subDomainInfo[2];
                                            }
                                            return (
                                                <div className="flex flex-col gap-y-4 gap-x-2 xl:flex-row xl:gap-y-0 justify-between" key={index}>
                                                    <div className="flex-col">
                                                        <p className="text-gray-500">Hostname</p>
                                                        <p className="text-sm">{hostname}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-gray-500">Type</p>
                                                        <p className="text-sm">{type}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-gray-500">Data/URL</p>
                                                        <p className="text-sm">{url}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            
                            )
                        })
                    }
                </div>
            }
            {
                deploymentInfo &&
                <div className="flex flex-col">
                    <h1 className="font-bold text-2xl pb-4">Deployment Information</h1>
                    <div className="grid grid-cols-1 gap-2">
                        <div className="border-2 p-2 rounded-md">Deployment No: <span className="text-gray-700">{jobSummary?.jobId}</span></div>
                        <div className="border-2 p-2 rounded-md">Commit Time: <span className="text-gray-700">{convertToIST(jobSummary?.commitTime)}</span></div>
                        <div className="border-2 p-2 rounded-md">Job Start TIme: <span className="text-gray-700">{convertToIST(jobSummary?.startTime)}</span></div>
                        <div className="border-2 p-2 rounded-md">Job End Time: <span className="text-gray-700">{convertToIST(jobSummary?.endTime)}</span></div>
                        <div className="border-2 p-2 rounded-md">Job Status: <span className="text-gray-700">{jobSummary?.status}</span></div>
                        {
                            deploymentStages?.map((stage: any, index: number) => (
                                <div className="flex flex-col border-2 p-2 rounded-md gap-y-2">
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="bg-sky-600 text-white p-1 rounded-md">{stage?.stepName}</span>
                                        <span className={`p-1 rounded-md ${stage?.status == 'SUCCEED' ? 'bg-emerald-600' : 'bg-red-600'}`}>{stage?.status}</span>
                                    </div>
                                    <div>Start Time: <span className="text-gray-700">{convertToIST(stage?.startTime)}</span></div>
                                    <div>End TIme: <span className="text-gray-700">{convertToIST(stage?.endTime)}</span></div>
                                </div>
                            )) 
                        }
                    </div>
                </div>
            }
        </div>
    )
    
}

export default BusinessDetails;