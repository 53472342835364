import { api } from '../api'

export const paymentApi = api.injectEndpoints({
    endpoints: (build) => ({
        choosePlan: build.mutation({
        query: (body) => ({
            url: `business/payment`,
            method: 'POST',
            body,
        }),
        }),
        getPayment: build.query({
            query: ( params:any ) => ({ url: 'business/payment' }),
        }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useChoosePlanMutation,
    useGetPaymentQuery
  } = paymentApi
  
  export const {
    endpoints: { getPayment },
  } = paymentApi
  