import React, { useState, useEffect } from "react";
import { useTranslation } from "../../../hooks/store";


type Props = {
    label?: string,
    name?: string,
    id: string,
    value?: string,
    setValue?: any,
    required?: boolean,
    placeholder?: string,
    readOnly?: boolean
};
const Text = ({ label, id, value, setValue, required, name, placeholder, readOnly }: Props) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);
    
    return (<>
        <div className="mb-2">
            <label className="block text-gray-700 font-medium mb-2" htmlFor={id}>
                {label}
            </label>
            <input
                className={`${readOnly ? "outline-none text-gray-500" : ""} border border-gray-400 p-2 rounded-lg w-full`}
                type="text"
                id={id}
                name={name}
                value={localValue}
                onChange={(e) => { setLocalValue(e.target.value); setValue && setValue(e.target.value); }}
                required={required}
                placeholder={placeholder}
                readOnly={readOnly}
            />
        </div>
    </>);
}
type selectProps = Props & {
    options: [
        { value: string, label: string }
    ],

}
export const Hidden = ({ value, name }: Props) => {
    const [localValue, setLocalValue] = useState(value);
    return (<>
        <input
            className="border border-gray-400 p-2 rounded-lg w-full"
            type="hidden"
            name={name}
            value={localValue}
        />
    </>);
}
export const Select = ({ label, id, value, setValue, required, options }: selectProps) => {
    const [localValue, setLocalValue] = useState(value);
    return (<>
        <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2" htmlFor={id}>
                {label}
            </label>
            <select
                className="border border-gray-400 p-2 rounded-lg w-full"
                id={id}
                value={localValue}
                name={id}
                onChange={(e) => { setLocalValue(e.target.value); setValue(e.target.value); }}
                required={required}
            >
                {
                    options.map((opt) => {
                        return <option key={opt.value} value={opt.value} >{opt.label}</option>
                    })
                }
            </select>
        </div>
    </>);
}
type ImageProps = {
    label: string,
    id: string,
    value?: string,
    setValue?: any,
    required?: boolean,
    setFileType?: any
};
export const Image = ({ label, id, required, setValue, setFileType, value }: ImageProps) => {
    const [logoPreview, setLogoPreview] = useState(value);
    required = value ? false : required;
    const preparePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setLogoPreview(URL.createObjectURL(e.target.files[0]));
            if (setFileType) {
                setFileType(e.target.files[0].type);
            }
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                // convert image file to base64 string and save to localStorage
                const dataURL = reader.result as string;
                setValue(dataURL);
            }, false);
            reader.readAsDataURL(e.target.files[0]);

        }

    }





    return (<>
        <div className="mb-4">
            <label
                htmlFor={id}
                className="mb-4 font-bold text-gray-700 cursor-pointer hover:text-blue-500 transition duration-300"
            >
                {
                    logoPreview ? <img className="h-20" src={logoPreview} alt="" /> :
                        <svg className="svg-icon h-10" viewBox="0 0 20 20">

                            <path d="M18.555,15.354V4.592c0-0.248-0.202-0.451-0.45-0.451H1.888c-0.248,0-0.451,0.203-0.451,0.451v10.808c0,0.559,0.751,0.451,0.451,0.451h16.217h0.005C18.793,15.851,18.478,14.814,18.555,15.354 M2.8,14.949l4.944-6.464l4.144,5.419c0.003,0.003,0.003,0.003,0.003,0.005l0.797,1.04H2.8z M13.822,14.949l-1.006-1.317l1.689-2.218l2.688,3.535H13.822z M17.654,14.064l-2.791-3.666c-0.181-0.237-0.535-0.237-0.716,0l-1.899,2.493l-4.146-5.42c-0.18-0.237-0.536-0.237-0.716,0l-5.047,6.598V5.042h15.316V14.064z M12.474,6.393c-0.869,0-1.577,0.707-1.577,1.576s0.708,1.576,1.577,1.576s1.577-0.707,1.577-1.576S13.343,6.393,12.474,6.393 M12.474,8.645c-0.371,0-0.676-0.304-0.676-0.676s0.305-0.676,0.676-0.676c0.372,0,0.676,0.304,0.676,0.676S12.846,8.645,12.474,8.645"></path>
                        </svg>
                }

                {label}
            </label>
            <input
                id={id}
                type="file"
                name={id}
                className="opacity-0 absolute"
                onChange={preparePreview}
                required={required}
            />
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
    </>);
}




type SelectAndSearchProps = {
    label?: string,
    id?: string,
    value?: string,
    setValue?: any,
    required?: boolean,
    multiSelect?: boolean,
    options: [
        { value: string, label: string }?
    ],
}

export const SelectAndSearch = ({ label, id, value, setValue, required, multiSelect, options }: SelectAndSearchProps) => {
    let list: string[] = [];
    console.log("value from search", value)

    const [selectedValue, setSelectedValue] = useState(value);
    const [selectedValues, setSelectedValues] = useState(list);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const t = useTranslation();


    const getLabelByValue = (value: string) => {
        const result = options?.filter((option) => {
            return option?.value === value;
        })

        return result && result[0] ? result[0].label ?? '' : '';
    }

    const handleItemChange = (val: string) => {
        setSelectedValue(val);
        setSearchTerm('');
        toggleDropdown();

        setValue(val);
    }

    const filterOptions = () => {
        if (!searchTerm) {
            return options;
        }
        const filteredOptions = options.filter(option =>
            option?.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filteredOptions;
    }

    const toggleDropdown = () => {
        console.log(selectedValue);
        setIsOpen(!isOpen);
        setSearchTerm('');
    }

    useEffect(() => {
        setValue(selectedValue);
        console.log(selectedValue);
    }, [selectedValue, setValue]);

    useEffect(() => {
        let items = selectedValues;
        let labelList = value?.split(',');
        console.log(labelList);
        labelList?.map((val) => {
            if (items.includes(val))
                items.splice(items.indexOf(val), 1);
            else
                items.push(val);
            return val;
        });
        console.log("items after useEffect: ", items);
        setSelectedValues(items);

    }, [isOpen, selectedValues, value]);

    const handleMultipleItemChange = (val: string) => {
        console.log("value on handle", selectedValue)
        //let label = getLabelByValue(val);
        let items = selectedValues;
        if (items.includes(val))
            items.splice(items.indexOf(val), 1);
        else
            items.push(val);

        setSelectedValues(items);
        console.log(selectedValues);
        setSelectedValue('')
        items.map((item) => {
            setSelectedValue((prev) => prev ? prev + ',' + item : item);
            return item;
            //console.log("selectedValue: ", selectedValue);
        })

    }

    const showLabels = (list: string | undefined) => {
        let labelList = list?.split(',');
        let finalLabel = "";
        labelList?.map((val) => {
            finalLabel += (getLabelByValue(val) + ',');
            return val;
        });

        return finalLabel.substring(0, finalLabel.length - 1);
    }

    return (
        <div className="relative w-45 min-h-min">
            <label className="block text-gray-700 font-medium mb-2" htmlFor={id}>
                {label}
            </label>
            <button onClick={toggleDropdown} type="button" className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                <span className="flex items-center">
                    <span className="ml-3 block truncate"> {multiSelect ? (selectedValue ? showLabels(selectedValue) : selectedValues.length >= 1 ? showLabels(selectedValue) : t("Choose")) : (selectedValue ? getLabelByValue(selectedValue) : selectedValue ? getLabelByValue(selectedValue) : t("Select"))}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clipRule="evenodd" />
                    </svg>
                </span>
            </button>
            {<input id={id} type="hidden" name={id} value={selectedValue} required={required} />}
            {isOpen && (
                <div>
                    <input
                        className="relative w-full block bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(event: any) => setSearchTerm(event.target.value)}
                    />
                    <ul className='absolute z-10 mt-1 max-h-20 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm transition-opacity opacity-100 divide-y-2 scroll-smooth hover:scroll-auto' role="listbox" aria-labelledby="listbox-label" >

                        {filterOptions().map(option => (
                            <li key={option?.value} value={option?.value} onClick={() => multiSelect ? handleMultipleItemChange(option?.value ?? '') : handleItemChange(option?.value ?? '')} className="font-normal ml-3 block truncate cursor-pointer sm:text-sm sm:leading-10 ">
                                <span>{option?.label}
                                    {selectedValue?.includes(option ? option?.value : '') &&
                                        <span className="relative right-0 inline-block pr-4 float-right">✔️</span>
                                    }
                                </span>
                            </li>
                        ))}

                    </ul>
                </div>
            )}

        </div>
    )
}

export default Text;