const baseURL = process.env.REACT_APP_API_URL; // Replace with your API base URL


export const fetchData = async (endpoint: any) => {
  try {
    if (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== 'undefined') {

      var storageData = JSON.parse(localStorage.getItem('userData') ?? '{}');
      const credentials = storageData.user_cred;

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${credentials.idToken}`,
        'X-Amz-Security-Token': credentials.token ?? '',
        'Business-Id': storageData?.current_business?.id
      };
      console.log(`${baseURL}${endpoint}`, headers, "jjjjjhhh");
      const response = await fetch(`${baseURL}${endpoint}`, {
        headers,
        cache: "no-store"
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } else {
      return { success: false, message: "login token expire kindly refresh" }
    }

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const handleResponse = (response: any) => {
  if (!response.ok) {
    const error = response.json();
    throw new Error(error.message || 'Something went wrong');
  }
  return response.json();
};



export const postData = async (endpoint: any, postData: any, type: string = 'POST') => {
  try {
    if (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== 'undefined') {
      var storageData = JSON.parse(localStorage.getItem('userData') ?? '{}');
      const credentials = storageData.user_cred;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${credentials.idToken}`,
        'X-Amz-Security-Token': credentials.token ?? '',
        'Business-Id': storageData?.current_business?.id
      };

      const response = await fetch(`${baseURL}${endpoint}`, {
        method: type,
        headers,
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      return result;
    } else {
      return { success: false, message: "login token expire kindly refresh" }
    }
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

