import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import logo from '../../images/logo.png';
import TermsAndCondtion from './form/TermsAndCondition';
import MobileNumberInput from './form/MobileNumberInput';
import { useLocation } from "react-router-dom";

import {
    addToast
} from '../toast/toastSlice';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
import PasswordInput from './form/PasswordInput';
type Props = {
    setFormType: any,
    setConfirmationData: any
    setUser: any
}
function Signup({ setFormType, setConfirmationData, setUser }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    var poolData = {
        UserPoolId: process.env.REACT_APP_POOL_ID ?? '', // Your user pool id here
        ClientId: process.env.REACT_APP_CLIENT_ID ?? '', // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // access query parameters
    const paramValue = queryParams.get("email") as string;
    const [email, setEmail] = useState(paramValue);
    const [password, setPassword] = useState('');
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    //child component validation handling
    const [isChildValid, setChildValid] = useState({ validation: true, message: "no prob" });
    const [mobileNumberInput, setMobileNumberInput] = useState({ ext: 'string', phone_number: 'string' });
    const handleMobileNumberInput = (value: any) => {
        setMobileNumberInput(value);
    }

    const handlChildValidation = (value: any) => {
        setChildValid(value);
    };
    const handlPassword = (value: any) => {
        setPassword(value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (typeof isChildValid.validation && !isChildValid.validation) {
            dispatch(addToast({ type: "error", message: isChildValid.message }));
            return;
        }
        if (!passwordRegex.test(password)) {
            dispatch(addToast({ type: "error", message: 'Invalid password format' }));
            return;
        }
        var attributeList = [];
        var extraData = {
            Name: "phone_number",
            Value: mobileNumberInput.ext + mobileNumberInput.phone_number
        };
        var dataEmail = {
            Name: 'email',
            Value: email,
        };

        var attributeEmail = new CognitoUserAttribute(dataEmail);
        var ExtraAttribute = new CognitoUserAttribute(extraData);
        attributeList.push(attributeEmail);
        attributeList.push(ExtraAttribute);

        userPool.signUp(email, password, attributeList, null!, function (
            err,
            result
        ) {
            console.log(result, 'result');
            if (err) {
                //console.log(err.message || JSON.stringify(err));
                dispatch(addToast({ type: "error", message: err.message || JSON.stringify(err) }));
                return;
            }

            var cognitoUser = result ? result.user : false;
            if (result && cognitoUser) {
                if (result && result.userConfirmed) {
                    dispatch(addToast({ type: "success", message: 'Account created successfully.' }));
                    setFormType('login');
                    navigate("/login");
                }
                else {
                    setUser({ username: email, email: email });
                    setConfirmationData(result.codeDeliveryDetails);
                    setFormType('confirm');
                }


            }

        });

    }

    return (
        <>
             <div className=" flex font-gtbuddy items-center gap-1 justify-center py-3">
                <img  src={logo}  className=" h-8 w-8  " alt=""/>
                <h1 className="font-bold text-2xl"><span>GT</span><span className=" text-[#ea7a00]">Buddy</span></h1>
        </div>
            <div>
                {/* <h1 className="text-xl fo font-bold text-neutral-950 text-center">Create ShikshaSe Account</h1> */}
                <p className="  text-neutral-500 text-bold text-center"> One last step before your journey start</p>
            </div>
            <form onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST" className="flex flex-col gap-4">
                <div className="flex flex-col">
                    <label className="text-neutral-800">Email</label>
                    <input
                        className="p-3 rounded-xl focus:outline-none focus:border-blue-500 border border-neutral-950 text-neutral-700"
                        type="email"
                        id="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                    <MobileNumberInput CountryCode="IN" onChange={handlChildValidation} MobileValues={handleMobileNumberInput}></MobileNumberInput>
                </div>
                <PasswordInput onChange={handlPassword} label="Password" />
                <TermsAndCondtion></TermsAndCondtion>
                <button className="bg-[#303030] py-3 rounded-xl text-white mt-1.5" >Create your GTBuddy Account</button>
            </form>

            <div className="text-sm">
                <span>Already have a GTBuddy Account?</span>
                <span className="text-blue-700 pl-2"><a href="/login">Login</a></span>
            </div>
        </>
    );
}
export default Signup;