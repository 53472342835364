
import { useState } from 'react';

function Faq(){
    const [toggle, settoggle]=useState(false);
    const [toggle1, settoggle1]=useState(false);

    return(
       
        <div className="flex flex-col justify-center items-center py-16 md:px-16 px-5  gap-10 font-gtbuddy">
            <div>
              <h1 className=" md:text-4xl text-2xl font-bold py-2 ">Frequently asked questions</h1>
            </div>
            <div className=" w-full md:w-4/5 grid grid-cols-1 gap-6" >
                <div className=" flex justify-between items-center px-3 ">
                        <div className="text-xl">
                            What is a website builder?
                        </div>
                        <div className="relative question hover:cursor-pointer p-2 " onClick={()=>settoggle(!toggle)} >
                        <div  id="minus"className="absolute h-0.5 w-5 bg-black right-1"></div>
                        <div id="plus" className="absolute h-0.5 w-5 bg-black rotate-90 right-1"></div>
                        </div>
                </div>
               { toggle&&<div id="content" className=" py-2 p-5 ">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores aperiam reprehenderit sequi quisquam iusto eligendi ratione non repellendus cupiditate voluptatum. Voluptas dolorem at atque dolorum vitae iure inventore repellat et?
                </div>}
                <hr className=" border border-b-black w-full" />
            </div>
      
            <div className=" w-full md:w-4/5 grid grid-cols-1 gap-6" >
                <div className=" flex justify-between items-center px-3 ">
                    <div className="text-xl">
                        What is a website builder?
                    </div>
                    <div className="relative  question hover:cursor-pointer p-2  " onClick={()=>settoggle1(!toggle1)} >
                        <div  id="minus1"className="absolute h-0.5 w-5 bg-black right-1"></div>
                        <div id="plus1" className="absolute h-0.5 w-5 bg-black rotate-90 right-1"></div>
                    </div>
                </div>
               {toggle1&& <div id="content1" className="answer py-2  p-5">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores aperiam reprehenderit sequi quisquam iusto eligendi ratione non repellendus cupiditate voluptatum. Voluptas dolorem at atque dolorum vitae iure inventore repellat et?
                </div>}
                {/* <hr className= " border border-b-black w-full" /> */}
            </div>
        </div>
     
    )
}


export default Faq;