import {fetchData} from '../app/services/apiService';
interface SavedTheme {
    id ? : string;
    code ? : string;
}
interface ThemeData {
    themeId ? : string;
    themeCode : string;
    themeName?  : string;
    amplifyDefaultDomain? : string;
}

export const publishThemeOnSite = async(businessType:string,businessId:string, themeData : ThemeData, entityObj:any , publishedTheme :SavedTheme) => {
    const updateEntity = entityObj.updateEntity;
    const addEntity =  entityObj.addEntity;
    let themeId = themeData.themeId ?? '';
    let themeCode = themeData.themeCode;
    let themeName = themeData.themeName;
    console.log(themeId,businessId,'here');
    const tagging = 'public=yes';
    if((themeId === '' || themeId=='{themeId}')&& themeCode !==undefined)
    {
        let themeObject = {
            entity:`theme.${themeCode}`,
            status:"Draft",
            code:themeCode,
            type:businessType,
            name:themeName,
            amplifyDefaultDomain:themeData?.amplifyDefaultDomain
          }
          console.log(themeObject,'themeObject');
        let addEntityRes = await addEntity(themeObject);
        themeId = addEntityRes.data.id;
    }
    const pagesJsonUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${businessType}/${themeCode}/pages.json`;
    
    let pagesJson = [];
    try{
        const allPages = await fetch(pagesJsonUrl);
        pagesJson = await allPages.json();
    }
    catch(e){

    }
    
    const themeCodeData = {} as any;
    const defaultThemeData = `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${businessType}/${themeCode}/defaultData.json`;
    let defaultThemeResponseJson = {} as any;
    try{
        const defaultThemeResponse = await fetch(defaultThemeData);
        defaultThemeResponseJson = await defaultThemeResponse.json();
    }
    catch(e){

    }
   
    console.log(defaultThemeResponseJson,'defaultThemeResponseJson');
    await Promise.all(pagesJson.map(async (page:any)=>{
        const s3FileName = `business/${businessId}/themes/${themeCode}/${themeId}/${page.code}.json`;
        const s3PublishFileName = `business/${businessId}/themes/${themeCode}/${page.code}.json`;
        const s3FileUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}${s3FileName}`;
        const savedPageData = await fetch(s3FileUrl);
        let s3Bucket = process.env.REACT_APP_S3_BUCKET;
        let savedPageDataJson;
        if(savedPageData.ok){
            try{
                savedPageDataJson = await savedPageData.json();
            } catch(err) {
                savedPageDataJson = {};
                console.log(err);
            }
            if(!page.is_lazy){
                themeCodeData[page.code] = savedPageDataJson;
            } else {
                themeCodeData[page.code] = {is_lazy:true,lazy_code:page.code};
            }
            
            const getSignedUrl = await fetchData(`signed-s3?file_name=${s3PublishFileName}&bucket_name=${s3Bucket}`);
            await fetch(getSignedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json' ,
                    'x-amz-tagging': 'public=yes'
                },
                body: JSON.stringify(savedPageDataJson)
            });
        } else {
            const getUrl = await fetchData(`signed-s3?file_name=${s3FileName}&bucket_name=${s3Bucket}`);
            await fetch(getUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json' ,
                'x-amz-tagging': tagging
            },
             body: JSON.stringify(defaultThemeResponseJson[page.code]??{})
            });

            if(!page.is_lazy){
                themeCodeData[page.code] = defaultThemeResponseJson[page.code]??{};
            } else {
                themeCodeData[page.code] = {is_lazy:true,lazy_code:page.code};
            }
            console.log(themeCodeData,'themeCodeDatasss');

            const getSignedUrl = await fetchData(`signed-s3?file_name=${s3PublishFileName}&bucket_name=${s3Bucket}`);
            await fetch(getSignedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json' ,
                    'x-amz-tagging': 'public=yes'
                },
                body: JSON.stringify(defaultThemeResponseJson[page.code]??{})
            });
        }
    }));
    const s3ThemeCodePath = `business/${businessId}/themes/${themeCode}.json`;
    let s3Bucket = process.env.REACT_APP_S3_BUCKET;
    const themeCodeUrl = await fetchData(`signed-s3?file_name=${s3ThemeCodePath}&bucket_name=${s3Bucket}`);
    
    if(Object.keys(themeCodeData).length > 0)
    {
        console.log(themeCodeData,themeCodeUrl,s3ThemeCodePath,'ranjeetsssss');
        await fetch(themeCodeUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json' ,
                'x-amz-tagging': 'public=yes'
            },
            body: JSON.stringify(themeCodeData)
        });

    }

    console.log(themeCodeData,'ankitssshfhhfhfhhfhfhfhhfhf');  

    if(publishedTheme && publishedTheme.id !== themeId )
    {
        let themeObject = {
        id : themeId,
        entity:`theme.${themeCode}`,
        status:"Published",
        businessType
        }
        updateEntity(themeObject);
        let OldThemeObject = {
        id : publishedTheme.id,
        entity:`theme.${publishedTheme.code}`,
        status:"Draft",
        businessType
        }
        updateEntity(OldThemeObject);
    } else {
        let themeObject = {
            id : themeId,
            entity:`theme.${themeCode}`,
            status:"Published",
            businessType
            }
        updateEntity(themeObject);
    }  
    return {'suceess':true, 'message':'Theme Published Successfully'};

}
