import React from "react"
import { useState } from "react";
import Modal from "../../modal/Modal";
import TermAndConditionPage from "./fields/TermConditionPage";
const TermsAndCondition  = () => {
    const [isChecked, setIsChecked] = useState(false)
    const [isOpen,setIsOpen]=useState(false)
    const [isOpen1,setIsOpen1]=useState(false)
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked)
     
  };
  const handleModal = (): void => {
    const URL = "https://www.gtbuddy.com/termNcondition.html";
    const newWindow = window.open(URL, '_blank');
    if (newWindow) newWindow.focus();
  };
  const handleModal1 = (): void => {
    const URL = "https://www.gtbuddy.com/privacypolicy.html";
    const newWindow = window.open(URL, '_blank');
    if (newWindow) newWindow.focus();
  };


    return (
        <>
        <div className=" text-sm flex justify-center items-center gap-0.5  px-1">
            <label className="inline-flex items-center">
              <input
              required
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={`form-checkbox h-4 w-4 text-green-500 ${isChecked ? 'checked:bg-blue-500' : ''}`}/>
            </label>
              <p className=" flex gap-1 text-xs justify-center items-center ">
                <span>By proceeding, you agree to the</span> 
                <span onClick={handleModal}
                className="text-xs text-blue-700 hover:cursor-pointer">Term &amp; Conditions </span>
                <span>and</span>
                <span className="text-xs text-blue-700 hover:cursor-pointer"
                onClick={handleModal1}> Privacy Policy</span>
              </p>
           {/* {isOpen && (
                <Modal 
                onClick={()=>setIsOpen(false)} >
                <TermAndConditionPage title="Term & Condition"
                heading="heading"
                content=" A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph. For instance, in some styles of writing, particularly journalistic styles, a paragraph can be just one sentence long."/>
              </Modal>
           )}
           {isOpen1 && (
                <Modal 
                onClick={()=>setIsOpen1(false)} >
                <TermAndConditionPage title="Pravicy and Policy"
                heading="heading1"
                content=" A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph. For instance, in some styles of writing, particularly journalistic styles, a paragraph can be just one sentence long."/>
              </Modal>
           )} */}
        </div>
        </>
    );
}

export default TermsAndCondition;