import React, { useState } from 'react';
import type { User,ConfirmationData } from '../../app/services/auth'
import { useDispatch } from 'react-redux'
import { setLoader } from '../loader/loaderSlice';
import {
    addToast
} from '../toast/toastSlice';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
//https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js
type Props = {
    user: User,
    confirmationData: ConfirmationData,
    setFormType:any
  }
function Confirm({ user, confirmationData, setFormType }:Props) {
    const dispatch = useDispatch();
    var poolData = {
        UserPoolId: process.env.REACT_APP_POOL_ID ?? '', // Your user pool id here
        ClientId: process.env.REACT_APP_CLIENT_ID ?? '', // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    const [confirmationCode, setConfirmationCode] = useState('');

    const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(setLoader(true));
        var userData = {
            Username: user.username,
            Pool: userPool,
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                dispatch(setLoader(false));
                dispatch(addToast({type:"error",message:err.message}));
                console.log('confirmation error:', err.name, err.message);
                if (err.name == 'ExpiredCodeException') {

                }
                
                alert(err.message || JSON.stringify(err));
                return;
            }
            dispatch(setLoader(false));
            dispatch(addToast({type:"success",message:'Account confirmed successfully.'}));
           
            setFormType('login');
            //console.log('confirmation result ', result);
        });
    }
    function sendConfirmation() {
        dispatch(setLoader(true));
        let userData = {
            Username: user.username!,
            Pool: userPool,
        };
        let cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                dispatch(setLoader(false));
                console.log(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ', result);
        });
    }
    return (


        <> 
                 
                 <div>
                    <h1 className="text-xl fo font-bold text-neutral-950">Confirm GTBuddy Account</h1>
                    { confirmationData.Destination && <p className="  text-neutral-500 text-bold">Confirmation code is sent on {confirmationData.Destination} </p>}
                </div>
                
                <form className="flex flex-col gap-4" onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST">
                    <div className="flex flex-col">
                        <label className="text-neutral-800" htmlFor="confirmAccount">
                            Confirmation Code
                                    </label>
                        <input
                            className="p-3 rounded-xl focus:outline-none focus:border-blue-500 border border-neutral-950 text-neutral-700"
                            type="text"
                            id="confirmationCode"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                    </div>

                    <button
                        
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Submit
                        </button>
                        <button
                            className=" text-indigo-500 my-5 py-2 px-4 "
                            onClick={(e) => { e.stopPropagation(); sendConfirmation();  }}
                        >
                            Resend confirmation code
                        </button>
                </form>
           
        </>
    );
}
export default Confirm;