import { useUpdateBusinessMutation } from "../../../app/services/api/business";
import { useGetAppQuery } from "../../../app/services/api/app"
import { selectCurrentBusiness, setCurrentBusiness } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../forms/form/Buttons";
import { useEffect, useState } from "react";
import { addToast } from "../../../features/toast/toastSlice";
import { setLoader } from "../../../features/loader/loaderSlice";
import Modal from "../../modal/Modal";
import Text from "../../forms/form/Fields";
import Spinner from "../../icons/Spinner";

// PENDING_DEPLOYMENT
// PENDING_VERIFICATION
export const BusinessSettings = () => {
    let currentBusiness = useSelector(selectCurrentBusiness);
    const [updateEntity] = useUpdateBusinessMutation();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(currentBusiness?.name);
    const [address, setAddress] = useState(currentBusiness?.address || "");
    const [amplifyDefaultDomain, setAmplifyDefaultDomain] = useState(currentBusiness?.amplifyDefaultDomain || "");
    const [domainUrl, setDomainUrl] = useState('');
    const [domainList, setDomainList] = useState(new Set());

    const amplifyAppId = currentBusiness?.amplifyAppId;
    const accountId = currentBusiness?.account;
    const customDomain = currentBusiness?.customDomain;
    const domainString = customDomain && encodeURIComponent(customDomain?.join(','));
    console.log("CustomDomain: ", customDomain)
    const params = `appId=${amplifyAppId}&account=${accountId}&domainName=${domainString}`;
    const paramsForDomainAssociation = amplifyAppId && accountId && customDomain;
    
    const {data: domainData, isLoading } = useGetAppQuery(params, {skip: !paramsForDomainAssociation});
    console.log("DomainData: ", domainData);
    console.log("CurrentBusiness: ", currentBusiness);

    useEffect(() => {
        setName(currentBusiness?.name);
        setAddress(currentBusiness?.address || "");
        setAmplifyDefaultDomain(currentBusiness?.amplifyDefaultDomain || "");
        console.log("changed")
    }, [currentBusiness])

    const ValidUrlRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
    const errorElement = document.getElementById("errorMsg");

    const business = {
        id: currentBusiness?.id,
        entity: currentBusiness?.entity
    }

    const businessUpdate = async(data: any) => {
        dispatch(setLoader(true));
        try {
            const updatedBusiness = {
                ...currentBusiness,
                ...data,
            };
            console.log("UpdatedBusiess: ", updatedBusiness)
            await updateEntity(updatedBusiness).unwrap();
            dispatch(setCurrentBusiness(updatedBusiness));
            dispatch(addToast({ type: "success", message: "Business updated successfully." }));
            dispatch(setLoader(false));
        } catch (err) {
            console.error('Failed to update business:', err);
            dispatch(addToast({ type: "error", message: "Unable to update business." }));
            dispatch(setLoader(false));
        }
    }

    const handleAddress = (address: string) => {
        setAddress(address);
    }

    const setVerifiedDomainToBusiness = async(domain: string) => {
        await businessUpdate({ verifiedCustomDomain: [...(currentBusiness?.verifiedCustomDomain || []), domain ]})
    }

    const handleSubmit = async(e:any) => {
        e.preventDefault();
        await businessUpdate({name, address});
    }

    const handleModal = () => {
        setIsOpen(!isOpen);
    }

    const handleDomainUrl = (e: any) => {
        setDomainUrl(e.target.value.toLowerCase());
        if(!ValidUrlRegex.test(e.target.value)) {
            if(errorElement){
                errorElement.style.display = "flex";
            }
        }
        else if(errorElement && ValidUrlRegex.test(e.target.value)){
            errorElement.style.display = "none";
        }
    }

    const addCustomDomain = async() => {
        if(currentBusiness?.customDomain?.includes(domainUrl)){
            dispatch(addToast({ type: "error", message: "Domain already present in the business" }));
            return;
        }
        await businessUpdate({
            customDomain: [...(currentBusiness?.customDomain || []), domainUrl.toLowerCase()],
            amplifyAppId: currentBusiness?.amplifyAppId,
            accountId: currentBusiness?.account
        });
        setDomainUrl('');
        setIsOpen(false);
    }

    useEffect(() => {
        paramsForDomainAssociation && domainData && domainData.length > 0 && domainData.map((domain, index) => {
            const domainName = domain?.domainAssociation?.domainName;
            if(domainName) {
                if(!domainList.has(domainName) && !currentBusiness?.verifiedCustomDomain?.includes(domainName) && domain?.domainAssociation?.domainStatus === "AVAILABLE") {
                    setVerifiedDomainToBusiness(domainName);
                    setDomainList(prevList => new Set(prevList).add(domainName));
                    console.log("Called for verification");
                }
            }
        })
    }, [domainData, currentBusiness, domainList]);

    return (
        <div className="flex flex-col bg-[#f1f1f1] gap-y-16 justify-center py-8">
            <div className="flex flex-col w-[80%] justify-center mx-auto bg-white p-8 rounded-md gap-y-4">
                <div className="flex items-center justify-between">
                    <h2 className="font-bold text-xl text-center">Business Settings</h2>
                    <Button label="Save" customClass="flex bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold px-4 py-2 rounded-md" onClick={handleSubmit} />
                </div>
                <form className="flex flex-col gap-y-2" action="" method="PATCH">
                    <Text label="Name" id="name" value={name} required readOnly />
                    <Text label="Address" id="address" value={address} setValue={handleAddress} required />
                </form>
            </div>
            {
                amplifyDefaultDomain ?
                <div className="flex flex-col w-[80%] justify-center mx-auto bg-white p-8 mb-16 rounded-md gap-y-8">
                    <div className="flex flex-col gap-y-4">
                        <h2 className="font-bold text-xl text-center">Domain Settings</h2>
                        <Text label="Amplify URL" id="amplify_url" value={amplifyDefaultDomain} readOnly />
                        {
                            isLoading ? <Spinner/> :
                            paramsForDomainAssociation && domainData && domainData.length > 0 && (
                                domainData[0]?.domainAssociation?.domainStatus === "AVAILABLE" ? (
                                    <div className="flex items-center gap-x-4">
                                        <div className="font-bold text-lg">Verified custom domain: </div>
                                        <div className="bg-pink-100 p-1 rounded">www.{domainData[0]?.domainAssociation?.domainName}</div>
                                    </div>
                                ) : (
                                    <div className="font-bold text-lg">Custom domain verification in under progress. Please revisit later.</div>
                                )
                            )
                        }
                        {
                            !(currentBusiness?.customDomain && currentBusiness?.customDomain?.length > 1)  &&
                        <Button 
                            label="Add Custom Domain" 
                            customClass="flex justify-center mx-auto bg-blue-500 text-white py-2 rounded-md font-bold hover:bg-blue-700 w-[200px]" 
                            onClick={handleModal}
                        />
                        }
                    </div>
                    {
                        paramsForDomainAssociation && domainData && domainData?.map((domain, index) => {
                            let certificateVerificationDNSRecord = domain?.domainAssociation?.certificateVerificationDNSRecord?.split(' ');
                            let hostName, type, url;
                            if(certificateVerificationDNSRecord) {
                                hostName = certificateVerificationDNSRecord[0];
                                type = certificateVerificationDNSRecord[1];
                                url = certificateVerificationDNSRecord[2];
                            }
                            if(index !==0 ) {
                            return (
                                <div className="flex flex-col gap-y-8 border border-gray-500 p-4 rounded-md" key={index}>
                                <div className="flex flex-row gap-x-2">
                                    <h2 className="font-bold">Custom domain: {domain?.domainAssociation?.domainName}</h2>
                                    <p className="font-bold text-sm text-rose-500 border border-rose-500 rounded-full px-2">{index}</p>
                                </div>
                                <div className="flex flex-row gap-32">
                                    <div className="flex-col">
                                        <p className="text-gray-500">Status</p>
                                        <p className="text-sm">{domain?.domainAssociation?.domainStatus}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-4">
                                    <h2 className="font-bold">Verifying domain ownership...</h2>
                                    <h4>Configure the following records associated with your domain.</h4>
                                    <h2 className="font-bold">Add a CNAME with these values</h2>
                                    <div className="flex flex-col gap-x-2 gap-y-4 xl:flex-row xl:gap-y-0 justify-between">
                                        <div className="flex-col">
                                            <p className="text-gray-500">Hostname</p>
                                            <p className="text-sm">{hostName}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-gray-500">Type</p>
                                            <p className="text-sm">{type}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-gray-500">Data/URL</p>
                                            <p className="text-sm">{url}</p>
                                        </div>
                                    </div>
                                    <h2 className="font-bold">Configure these records to point your subdomains to the Amplify domain</h2>
                                    {
                                        domain?.domainAssociation?.subDomains?.map((subDomain, index) => {
                                            let subDomainInfo = subDomain?.dnsRecord?.split(' ');
                                            let hostname, type, url;
                                            if(subDomainInfo) {
                                                hostname = subDomainInfo[0];
                                                type = subDomainInfo[1];
                                                url = subDomainInfo[2];
                                            }
                                            return (
                                                <div className="flex flex-col gap-y-4 gap-x-2 xl:flex-row xl:gap-y-0 justify-between" key={index}>
                                                    <div className="flex-col">
                                                        <p className="text-gray-500">Hostname</p>
                                                        <p className="text-sm">{hostname}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-gray-500">Type</p>
                                                        <p className="text-sm">{type}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-gray-500">Data/URL</p>
                                                        <p className="text-sm">{url}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="font-bold text-amber-700">NOTE: Once these have been added to your domain provider return to this page and ownership will verify momentarily.</div>
                                </div>
                            </div>
                            )}
                        })
                    }
                </div> :
                    <p className="font-bold text-lg text-center">Your business is not approved. Please wait for approval.</p>
            }

            {
                isOpen &&
                <Modal
                    submitAction={() => addCustomDomain()}
                    submitActionLabel="Save"
                    title="Custom Domain"
                    submitActionDisabled={!domainUrl}
                    onClick={handleModal}
                >
                <div className="block my-4">
                    <div className="col-span-2">
                    <input
                        type="text"
                        name="custom Domain"
                        id="customDomain"
                        value={domainUrl}
                        onChange={handleDomainUrl}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter Custom Domain URL"
                    />
                    <p id="errorMsg" className="text-red-600 pt-2 text-left text-xs ml-2 hidden">Please enter a valid URL !</p>
                    </div>
                </div>
                </Modal>
            }
        </div>
        
    )
}

export default BusinessSettings;