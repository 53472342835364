type Props = {
    className?: string,
    width?: number,
    height?: number,
    onClick?: any
}
export const Delete = ({ className, width, height, onClick }: Props) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"
                className={className}
                viewBox="0 0 24 24"
                width={width}
                height={height}
                onClick={onClick}
            >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path fill="currentColor" d="M6.5 9.5L8 8l4 4 4-4 1.5 1.5L13.5 14l4 4-1.5 1.5-4-4-4 4-1.5-1.5 4-4-4-4z" />
            </svg>
        </>
    );
}