import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import Toast from '../features/toast/messages'
import PageLoader from '../features/loader/PageLoader'

export function PrivateOutlet() {
  const auth = useAuth()
  const location = useLocation()
  if (auth.user) {

  } else {
    localStorage.removeItem('userData');
  }
  return auth.user ? (<>
    <Toast></Toast>
    <PageLoader />
    <Outlet />
  </>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  )
}