const Pharmacy = () =>{
    return(
        <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="health">
    <path 
        d="M52.29834,12.35005c-5.57373-4.602-14.13965-4.00586-19.50342,1.35742L32,14.50239l-.79492-.79492c-5.36328-5.3623-13.93018-5.959-19.50342-1.35742A14.11574,14.11574,0,0,0,6.51611,22.66987,14.26128,14.26128,0,0,0,10.667,33.41109L32,54.74409l21.333-21.333a14.26128,14.26128,0,0,0,4.15088-10.74122A14.11574,14.11574,0,0,0,52.29834,12.35005Zm.32764,20.354L32,53.33l-20.626-20.626a13.25835,13.25835,0,0,1-3.85888-9.98632,13.12413,13.12413,0,0,1,4.82324-9.5962,12.75351,12.75351,0,0,1,8.16553-2.86914,14.1803,14.1803,0,0,1,9.99414,4.16211L32,15.91646l1.502-1.502c5.00391-5.0039,12.98-5.57177,18.15967-1.293a13.12413,13.12413,0,0,1,4.82324,9.5962A13.25835,13.25835,0,0,1,52.626,32.70405ZM35.40381,22.26021H28.59619v6.02295h-6.023v6.80761h6.023v6.02246h6.80762V35.09077h6.023V28.28316h-6.023Zm5.023,7.02295v4.80761h-6.023v6.02246H29.59619V34.09077h-6.023V29.28316h6.023V23.26021h4.80762v6.02295Z"
        className="fill-current text-black"
        stroke="currentColor"
        strokeWidth="2.8" 
    />
</svg>

        </>
    )
}

export default Pharmacy