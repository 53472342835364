export const getTimeAsPerTimezone = (utcTime:any, timeZone="+5:30") => {
    const parsedUtcTime = new Date(utcTime);
    const utcOffsetInHours = parseFloat(timeZone);
   // const istTime = new Date(parsedUtcTime.getTime() + utcOffsetInHours * 3600000);
    const istTime = new Date(parsedUtcTime.getTime());
    const difference = new Date().getTime() - istTime.getTime();
    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const WEEK = DAY * 7;
    const MONTH = DAY * 30;
    const YEAR = DAY * 365;


    if (difference < MINUTE) {
        return 'just now';
    } else if (difference < HOUR) {
        return Math.floor(difference / MINUTE) + ' min ago';
    } else if (difference < DAY) {
        return Math.floor(difference / HOUR) + ' hrs ago';
    } else if (difference < WEEK) {
        return Math.floor(difference / DAY) + ' day' + (
        Math.floor(difference / DAY) > 1
            ? 's'
            : ''
        ) + ' ago';
    } else if (difference < MONTH) {
        return Math.floor(difference / WEEK) + ' week' + (
        Math.floor(difference / WEEK) > 1
            ? 's'
            : ''
        ) + ' ago';
    } else if (difference < YEAR) {
        return Math.floor(difference / MONTH) + ' month' + (
        Math.floor(difference / MONTH) > 1
            ? 's'
            : ''
        ) + ' ago';
    } else {
        return Math.floor(difference / YEAR) + ' year' + (
        Math.floor(difference / YEAR) > 1
            ? 's'
            : ''
        ) + ' ago';
    }
};

