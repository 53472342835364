import { Crud } from "../../crud/Crud";
import { useGetAllBusinessesQuery, businessApi } from "../../../app/services/api/business";
import t from "../../../app/services/translation";

const businessSchema = {
    primaryKey: 'id',
    title: 'Business',
    entityCode: 'business',
    attributes: {
        pk: {
            title: "Primary Key",
            code: "pk",
            dataIndex: "pk",
            required: true
        },
        id: {
            title: "Business ID",
            code: "id",
            dataIndex: "id",
            required: true
        },
        name: {
            title: "Business Name",
            code: "name",
            dataIndex: "name"
        },
        type: {
            title: "Business Type",
            code: "type",
            dataIndex: "type"
        },
        amplifyAppId: {
            title: "Amplify App ID",
            code: "amplifyAppId",
            dataIndex: "amplifyAppId"
        },
        amplifyDefaultDomain: {
            title: "Apmlify Default Domain",
            code: "amplifyDefaultDomain",
            dataIndex: "amplifyDefaultDomain"
        },
        accountId: {
            title: "Account ID",
            code: "accountId",
            dataIndex: "accountId"
        },
        username: {
            title: "Created By",
            code: "username",
            dataIndex: "username"
        }

    },
    headers: {
        pk: {},
        id: {},
        name: {},
        type: {},
        // amplifyAppId: {},
        // amplifyDefaultDomain: {},
        // accountId: {},
        // username: {},
        action: {
            title: "Action",
            code: "action",
            actions: {
                approve: {
                    title: t('View'),
                    class: 'text-indigo-600 hover:text-indigo-900 mr-4 cursor-pointer',
                    to: './:id'
                },
                disapprove: {
                    title: t('Delete'),
                    class: 'text-red-600 hover:text-red-900 mr-4 cursor-pointer',
                    to: './',
                    onClick: (event: any, data: any, addMessage: any) => {
                        console.log(data);
                        event.preventDefault();
                        if (window.confirm(t('Really want to delete %s ?', [data.entity]))) {
                            fetch(process.env.REACT_APP_API_URL + `business/${data.id}`, {
                                method: "DELETE"
                            })
                                .then(data => data.json())
                                .then((data) => {
                                    addMessage("success", "Business deleted successfully.");
                                })
                                .catch((e) => {
                                    addMessage("error", "Unable to delete business.");
                                });
                        }
                    }
                }
            }
        }

    }
}

export const FormExtraFields = (props: any) => {
    return <div>
    </div >
}

const BusinessListing = () => {
    const { data, isLoading } = useGetAllBusinessesQuery();

    return isLoading ? <></> : (
        <Crud title="Listed Business"
            gridRoute="/panel/manage/business"
            schema={businessSchema}
            data={isLoading ? [] : data?.items}
            allowedList={{ list: false, add: false, view: true, edit: false }}
            actions={{}}
            apiEndPoints={businessApi.endpoints}
            FormExtraFields={FormExtraFields}
        />
    )
}

export default BusinessListing;