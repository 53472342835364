import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type initialState = boolean;

const initialState = false;

const slice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (
            state, action: PayloadAction<boolean>
        ) => {
            console.log('setting state loader', action.payload);
            state = action.payload;
            return state;
        }
    }
})

export const { setLoader } = slice.actions;
export const selectLoader = (state: any) => state.loader;
export default slice.reducer;