import { api } from '../api'

export interface Activity {
    pk: string,
    sk?: string,
    input?: string,
}
export interface ActivitiesResponse {
  activities: Activity[],
  lastEvaluatedKey: any
}

export const activityApi = api.injectEndpoints({
    endpoints: (build) => ({
      getActivities: build.query<ActivitiesResponse, string>({
        query: ( params:any ) => ({ url: `activity?${params}` }),
        providesTags: (result) => result ? [
          ...result?.activities.map(({ pk }) => ({ type: 'Entity', pk }) as const),
          { type: 'Activities' as const, id: 'LIST' },
        ] : [{ type: 'Activities', id: 'LIST' }],
      }),
      updateEntity: build.mutation<Activity, Partial<Activity>>({
        query(data) {
          return {
            url: `business/entity`,
            method: 'PATCH',
            body:data,
          }
        },
        invalidatesTags: (data) => [{ type: 'Activity', pk: data?.pk }],
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useGetActivitiesQuery
  } = activityApi
  
  export const {
    endpoints: { getActivities },
  } = activityApi
  