const Education = () => (
    <svg width="56" height="56" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4.5L2 9.5L12 14.5L22 9.5L12 4.5Z" stroke="#000000"
            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        </path>
        <path d="M19 11V16L12 19.5L5 16V11" stroke="#000000" strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round">
        </path>
        <path d="M22 14V18" stroke="#000000" strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round">
        </path>
    </svg>
);

export default Education;