import React, { useState } from 'react';

type Props = {
    children: string | JSX.Element | JSX.Element[]
    title?: string
    handleSubmit: any,
    id?: string
}
const Form = ({ children, title, handleSubmit, id = '' }: Props) => {
    return (<>
        <div className="min-w-full">
            {title && <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                {title}
            </h2>
            }
            <form className="mt-8 space-y-6" id={id} onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST">
                {children}
            </form>
        </div>
    </>);
}
export default Form;