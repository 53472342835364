import { api } from '../api'

export interface Entity {
    id: string,
    code?: string,
    name?: string,
    entity?: string,
    type?: string,
    email?: string,
    status?: string,
    created_at?: string,
    updated_at?:string,

}
type EntitiesResponse = Entity[];
export const entityApi = api.injectEndpoints({
    endpoints: (build) => ({
      getEntities: build.query<EntitiesResponse, string>({
        query: ( params:any ) => ({ url: 'business/entity?'+params }),
        providesTags: (result = []) => [
          ...result.map(({ id }) => ({ type: 'Entity', id }) as const),
          { type: 'Entities' as const, id: 'LIST' },
        ],
      }),
      addEntity: build.mutation<Entity, Partial<Entity>>({
        query: (body) => ({
          url: `business/entity`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'Entities', id: 'LIST' }],
      }),
      getEntity: build.query<Entity, string>({
        query: (params) => `business/entity?${params}`,
        providesTags: (_post, _err, id) => [{ type: 'Entity', id }],
      }),
      updateEntity: build.mutation<Entity, Partial<Entity>>({
        query(data) {
          return {
            url: `business/entity`,
            method: 'PATCH',
            body:data,
          }
        },
        invalidatesTags: (data) => [{ type: 'Entity', id: data?.id }],
      }),
      deleteEntity: build.mutation<{ success: boolean; id: string }, Partial<Entity>>({
        query(data) {
            console.log('delete',data);
          return {
            url: `business/entity`,
            method: 'DELETE',
            body:data
          }
        },
        invalidatesTags: (post) => [{ type: 'Entities', id: post?.id }],
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useAddEntityMutation,
    useDeleteEntityMutation,
    useGetEntitiesQuery,
    useGetEntityQuery,
    useUpdateEntityMutation,
    useGetErrorProneQuery,
  } = entityApi
  
  export const {
    endpoints: { getEntity,getEntities,deleteEntity,updateEntity,addEntity },
  } = entityApi
  