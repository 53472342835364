import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { setCredentials } from '../auth/authSlice'
import type { User,LoginRequest } from '../../app/services/auth'
import { useNavigate } from 'react-router-dom'
import {
    addToast
} from '../toast/toastSlice';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
//https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js

type Props = {
    user: LoginRequest,
    confirmationData:any,
    cognitoUser:any,
    authenticate:any

  }
function Mfa({ confirmationData, cognitoUser, user, authenticate }:Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [confirmationCode, setConfirmationCode] = useState('');
    function reSendConfirmation() {
        authenticate(user);
    }
    const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        cognitoUser.sendMFACode(confirmationCode,
            {
                onSuccess: function (result:any) {
                    // console.log('mfa success', result);
                    let token = result.accessToken.getJwtToken();
                    let idToken = result.idToken.getJwtToken();
                    cognitoUser.getUserData(function (err:any, userData:any ){
                        if (err) {
                            console.log(err.message || JSON.stringify(err));
                            return;
                        }
                        else {
                            let user:User = {};
                            if(userData && userData.UserAttributes){
                                console.log('User data for user ', userData.UserAttributes);
                                userData.UserAttributes.forEach((element: { Name: string | number; Value: string; }) => {
                                    user[element.Name] = element.Value;
                                });
                                dispatch(setCredentials({user:user,token:token,idToken}));
                                dispatch(addToast({type:"success",message:"Loggedin successfully."}));
                                navigate('/');
                            }
                        }
                        console.log('User data for user ', userData);
                    });

                },
                onFailure: function (err:any) {
                    dispatch(addToast({type:"error",message:err.message}));
                    console.log('mfa failure:', err.name, err.message);
                }

            });
    }
    
    return (


        <>
            <div className="min-w-full  ">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Mafa component Enter Multifactor Authencation Code
            </h2>
            { confirmationData.Destination && <div>Confirmation code is sent on {confirmationData.Destination} </div>}
            <form className="mt-8 space-y-6" onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST">
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="confirmPassword">
                        Authencation Code
                                </label>
                    <input
                        className="border border-gray-400 p-2 rounded-lg w-full"
                        type="text"
                        id="confirmationCode"
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                </div>

                <button
                   
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Submit
                    </button>
                    <button
                            type="button"
                            className=" text-indigo-500 my-5 py-2 px-4 "
                            onClick={(e) => { e.stopPropagation(); reSendConfirmation();  }}
                        >
                            Resend confirmation code
                        </button>
            </form>
            </div>
        </>
    );
}
export default Mfa;