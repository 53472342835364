import React, { useEffect, useRef, useState } from "react";
import Logo from "./shikshase12.png";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentBusiness, selectCurrentBusiness, logout } from "../../auth/authSlice";
import { useGetBusinessesQuery } from "../../../app/services/api/business";
import logo from '../../../images/logo.png'
const Navbar = (props: any) => {
  const { data: businesses, isLoading } = useGetBusinessesQuery();

  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const currentBusiness = useSelector(selectCurrentBusiness);
  //const [business, setBusiness] = useState( props.businessNames?.[0] || null);
  const [menuClicked, setMenuClicked] = useState(false)
  const navigate = useNavigate();
  const handleButtonOnClick = () => {
    setIsVisible(!isVisible)
    setMenuClicked(true)
  };
  const handleBusinessClick = (business: any) => {
    //setCurrentBusiness(business);
    dispatch(setCurrentBusiness(business));
    setIsVisible(false);
    setMenuClicked(false)
    console.log("Current Business:", business);
  };
  const handleLogOut = () => {
    dispatch(logout());
  };
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      const menu = document.getElementById("navbar-menu");

      if (menu && !menu.contains(e.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isVisible, menuClicked]);
  return (
    <>
      <div className="z-20 sticky top-0 flex justify-between items-center px-3 py-2 bg-white shadow-md">
        <div className="hidden md:block ">
          <Link to="./home" className="hover:cursor-pointer">
          <div className="flex items-center gap-2 text-xl font-extrabold">
          <img  src={logo}  className=" h-8 w-8  " alt=""/>
                <h1 className="font-bold text-2xl"><span>GT</span><span className=" text-[#ea7a00]">Buddy</span></h1>
          </div>
          </Link>
        </div>
        <div className="flex flex-col gap-1 md:hidden cursor-pointer" onClick={props.onClick}>
          <div className="h-1 w-8 bg-stone-300"></div>
          <div className="h-1 w-8 bg-stone-300"></div>
          <div className="h-1 w-8 bg-stone-300"></div>
        </div>
        <div className="flex items-center gap-6 text-white " >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="hidden"
            fill="white"
            id="notifications"
          >
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"></path>
          </svg>
          <div

            className="md:py-0.5 bg-[#6e6c6c] rounded-md flex px-0.5 text-xs"
            onClick={handleButtonOnClick}
          >
            <button
              className="px-2 "
              style={{ fontSize: "0.7rem" }}
            >
              {currentBusiness ? currentBusiness.name : ""}
            </button>
            <button
              className="py-1 px-1.5 rounded-md bg-[#40d2ba] text-black  "
              style={{ fontSize: "0.6rem" }}
            >
              {currentBusiness ? currentBusiness.name.slice(0, 2) : ""}
            </button>
          </div>
        </div>
      </div>
      {/* Design top right corner menu */}
      {isVisible && (
        <div className="absolute font-gtbuddy top-14 right-2 bg-white shadow-sm shadow-gray-100 p-1 w-48 rounded-md z-10">
          <div className="text-xs space-y-1" id="navbar-menu">
            {businesses?.map((business: any, index: number) => (
              <div
                key={index}
                className={`hover:bg-gray-100 hover:cursor-pointer p-0.5 rounded-md ${currentBusiness?.code === business.code ? "bg-gray-100" : "bg-white"
                  }`}
                onClick={() => handleBusinessClick(business)}
              >
                <p
                  className={`relative flex flex-wrap gap-2 items-center`}
                  style={{ fontSize: "0.7rem" }}
                >
                  <button
                    className={`relative rounded-md py-0.5 px-1 ${currentBusiness?.code === business.code
                      ? "bg-teal-400 text-black text-xs"
                      : "bg-[#b5bbfc] text-white "
                      }`}
                    style={
                      currentBusiness?.code === business.code ? { fontSize: "0.6rem" } : {}
                    }
                  >
                    {business.name.slice(0, 2)}
                  </button>
                  <span
                    className={`${currentBusiness?.code === business.code ? "font-semibold" : ""
                      }`}
                  >
                    {" "}
                    {business.name}
                  </span>
                  {currentBusiness?.code === business.code && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="w-4 h-3 text-gray-500 absolute top-1 right-0"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </>
                  )}
                </p>
              </div>
            ))}

            <div className="hover:bg-gray-100 p-1 rounded-md flex gap-2 hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
                />
              </svg>
              <p className=" "> All Business </p>
            </div>
            <hr />
            <div
              className="hover:bg-gray-100 p-1 px-2 rounded-md hover:cursor-pointer"
              onClick={handleLogOut}
            >
              <button className="text-xs">Log out</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Navbar;

