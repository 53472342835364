import React from "react"
type Props = {
    label : string,
    type? : any,
    customClass?: string,
    onClick?: any
}
const Button  = ({ label,type = "submit", customClass, onClick}: Props) => {
    return (
        <>
        <button
                type={ type }      
                className={customClass ?? "group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}
                onClick={onClick}
            >
                { label }
        </button>
        </>
    );
}

export default Button;