import { api } from '../api'

export interface Plan {
    pk: string,
    title: string,
    description?: string,
    validity: number,
    billed_type: string,
    price: number,
    sort_order?:number
    badge?: string,
    features?: []
}
export interface planResponse {
  Items: Plan[],
  lastEvaluatedKey?: any
}

export const planApi = api.injectEndpoints({
    endpoints: (build) => ({
      getPlan: build.query<planResponse, string>({
        query: ( params:any ) => ({ url: 'payment/plan?'+params }),
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useGetPlanQuery,
  } = planApi
  
  export const {
    endpoints: { getPlan },
  } = planApi
  