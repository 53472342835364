const Other = () => {
    return(
        <>

<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" className="w-14 h-14">
    <path
        d="M13 0C5.826 0 0 5.826 0 13s5.826 13 13 13 13-5.826 13-13S20.174 0 13 0zm0 1c6.633 0 12 5.367 12 12s-5.367 12-12 12S1 19.633 1 13 6.367 1 13 1zM6.998 11A2.006 2.006 0 0 0 5 13c0 1.099.9 2 1.998 2A2.01 2.01 0 0 0 9 13c0-1.099-.903-2-2.002-2zM13 11c-1.099 0-2 .901-2 2s.901 2 2 2 2-.901 2-2-.901-2-2-2zm6 0c-1.099 0-2 .901-2 2s.901 2 2 2 2-.901 2-2-.901-2-2-2zm-12.002.998A.996.996 0 0 1 8 13c0 .558-.444 1-1.002 1A.99.99 0 0 1 6 13c0-.558.44-1.002.998-1.002zm6.002 0c.558 0 1 .444 1 1.002s-.442 1-1 1-1-.442-1-1a.994.994 0 0 1 1-1.002zm6 0c.558 0 1 .444 1 1.002s-.442 1-1 1-1-.442-1-1a.994.994 0 0 1 1-1.002z"
        className="fill-current stroke-current"
        stroke-width="1.2"
    />
</svg>

        </>
    )
}

export default Other