const Agriculture = () =>{
    return(
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="wheat">
    <path 
        d="M3.75977,9.10645a6.65046,6.65046,0,0,0,1.63085,6.50293,6.54632,6.54632,0,0,0,4.63965,1.88769A6.91341,6.91341,0,0,0,11.5,17.3219V22a.5.5,0,0,0,1,0V17.3219a6.91341,6.91341,0,0,0,1.46973.17517,6.54632,6.54632,0,0,0,4.63965-1.88769,6.65046,6.65046,0,0,0,1.63085-6.50293.5.5,0,0,0-.34668-.34668,6.75945,6.75945,0,0,0-4.82489.44445A6.01336,6.01336,0,0,0,15.5,7a6.28688,6.28688,0,0,0-3.25488-5.43555.49837.49837,0,0,0-.49024,0A6.28688,6.28688,0,0,0,8.5,7a6.01259,6.01259,0,0,0,.43115,2.20355,6.756,6.756,0,0,0-4.8247-.44378A.5.5,0,0,0,3.75977,9.10645Zm15.58789.54589a5.51078,5.51078,0,0,1-6.69532,6.69532A11.88242,11.88242,0,0,1,12.5,14.86029a5.45843,5.45843,0,0,1,1.59766-3.76263A5.56248,5.56248,0,0,1,19.34766,9.65234ZM12,2.582A5.15369,5.15369,0,0,1,12,11.418,5.15369,5.15369,0,0,1,12,2.582ZM4.65234,9.65234a5.54371,5.54371,0,0,1,5.25,1.44532A5.45843,5.45843,0,0,1,11.5,14.86029a11.88242,11.88242,0,0,1-.15234,1.48737A5.51078,5.51078,0,0,1,4.65234,9.65234Z"
        className="fill-current text-black"
        stroke="currentColor"
        strokeWidth="0.5"
    />
</svg>

        </>
    )
}

export default Agriculture