import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { s3Api } from "../../../../app/services/api/s3";
import { selectTokens } from '../../../auth/authSlice';
import { Delete } from "../../../icons/Delete";
type ImageItems = {
    [key: string]: any,
}
type ImageProps = {
    label: string,
    id: string,
    value: ImageItems,
    setValue?: any,
    required?: boolean,
    setFileType?: any
};
const fetchStatusHandler = (response: any) => {
    console.log('fetchStatusHandler response', response);
    if (response.status === 200) {
        return response;
    } else {
        throw new Error(response.status);
    }
}
export const Images = ({ label, id, required, setValue, setFileType, value }: ImageProps) => {
    const tokens = useSelector(selectTokens);
    const [imagesPreview, setImagesPreview] = useState(value);
    required = value ? false : required;
    const preparePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            //setImagesPreview([...imagesPreview,URL.createObjectURL(e.target.files[0])]);
            if (setFileType) {
                setFileType(e.target.files[0].type);
            }
            class CFileReader extends FileReader {
                filename = '';
            }
            let fileList = e.target.files;
            let images: { [key: string]: any } = {};
            let url = process.env.REACT_APP_API_URL ?? '';
            for (let i = 0; i < fileList.length; i++) {

                let reader = new CFileReader();
                reader.filename = fileList[i].name;
                reader.addEventListener("load", function () {
                    const dataURL = this.result as string;
                    fetch(url + '/signed-s3?file_name=' + reader.filename, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${tokens.idToken}`,
                            'X-Amz-Security-Token': tokens.token ?? ''
                        }
                    })
                        .then(fetchStatusHandler)
                        .then(data => data.json())
                        .then((url) => {
                            const response = fetch(url, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'image/*',
                                },
                                body: dataURL
                            });

                            console.log('response data', url);

                        }).catch((e) => {
                            console.log(e.message)
                        });

                    images[fileList[i].name] = { id: reader.filename, name: reader.filename, image: dataURL, new: true };
                    if (Object.keys(images).length == fileList.length) {
                        setValue({ ...value, ...images });
                        setImagesPreview({ ...value, ...images });
                    }
                }, false);
                reader.readAsDataURL(fileList[i]);


            }


        }

    }



    return (<>
        <div className="mb-4 col-span-2">
            <div className="container mx-auto py-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {
                        Object.entries(imagesPreview).map(([key, image]) => {
                            return <div key={image.id} className="h-32">
                                <img className="h-auto max-h-full max-w-full rounded-lg mx-auto" src={image.image} />
                            </div>
                        })
                    }
                </div>
                <div>
                    <label
                        htmlFor={id}
                        className="mb-4 font-bold text-gray-700 cursor-pointer hover:text-blue-500 transition duration-300"
                    >


                        <svg className="svg-icon h-10" viewBox="0 0 20 20">

                            <path d="M18.555,15.354V4.592c0-0.248-0.202-0.451-0.45-0.451H1.888c-0.248,0-0.451,0.203-0.451,0.451v10.808c0,0.559,0.751,0.451,0.451,0.451h16.217h0.005C18.793,15.851,18.478,14.814,18.555,15.354 M2.8,14.949l4.944-6.464l4.144,5.419c0.003,0.003,0.003,0.003,0.003,0.005l0.797,1.04H2.8z M13.822,14.949l-1.006-1.317l1.689-2.218l2.688,3.535H13.822z M17.654,14.064l-2.791-3.666c-0.181-0.237-0.535-0.237-0.716,0l-1.899,2.493l-4.146-5.42c-0.18-0.237-0.536-0.237-0.716,0l-5.047,6.598V5.042h15.316V14.064z M12.474,6.393c-0.869,0-1.577,0.707-1.577,1.576s0.708,1.576,1.577,1.576s1.577-0.707,1.577-1.576S13.343,6.393,12.474,6.393 M12.474,8.645c-0.371,0-0.676-0.304-0.676-0.676s0.305-0.676,0.676-0.676c0.372,0,0.676,0.304,0.676,0.676S12.846,8.645,12.474,8.645"></path>
                        </svg>


                        {label}
                    </label>
                    <input
                        id={id}
                        type="file"
                        name={id}
                        className="opacity-0 absolute"
                        onChange={preparePreview}
                        required={required}
                        multiple
                    />
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                </div>

            </div >
        </div >


    </>);
}

type S3ImageProps = {
    label: string,
    id: string,
    s3BaseUrl: string,
    s3Directory: string,
    s3Bucket: string,
    value: ImageItems,
    setValue?: any,
    required?: boolean,
    setFileType?: any,
    isTemp?: boolean
    limit: number

};
export const S3Images = ({ label, id, s3BaseUrl, s3Bucket, s3Directory, required, setValue, value, isTemp, limit }: S3ImageProps) => {
    // const tokens = useSelector(selectTokens);
    const [imagesPreview, setImagesPreview] = useState(value);
    const [trigger] = s3Api.endpoints.getSignedUrl.useLazyQuery();
    required = value ? false : required;


    const preparePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {

            class CFileReader extends FileReader {
                filename = '';
            }

            let fileList = e.target.files;
            let images: { [key: string]: any } = {};
            for (let i = 0; i < fileList.length; i++) {

                let reader = new CFileReader();
                reader.filename = fileList[i].name;
                reader.addEventListener("load", function () {
                    const dataURL = this.result as string;
                    let fileName = s3Directory + '/' + reader.filename;
                    let queryString = "file_name=" + fileName;
                    queryString += "&bucket_name=" + s3Bucket;
                    let tagging = 'public=yes';
                    if (isTemp) {
                        queryString += "&is_temp=1";
                        //tagging += "&temp=1";
                    }
                    trigger(queryString).then(data => {
                        console.log('data', data);

                        let url: any = '';
                        let s3Url = s3BaseUrl + s3Directory + '/' + reader.filename;
                        let s3key: any = s3Directory + '/' + reader.filename;
                        if (isTemp) {
                            url = data?.data?.url;
                            s3Url = s3BaseUrl + data?.data?.key;
                            s3key = data?.data?.key;
                        }
                        else {
                            url = data?.data;
                        }
                        const response = fetch(url, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'image/*',
                                'x-amz-tagging': tagging
                            },
                            body: fileList[i]
                        }).then(data => data.text())
                            .then((data) => {
                                console.log('final upload response', data);

                                images[fileList[i].name] = { id: reader.filename, name: reader.filename, image: s3Url, new: true, s3Bucket: s3Bucket, key: s3key };
                                if (Object.keys(images).length == fileList.length) {
                                    //setValue({ ...value, ...images });
                                    setImagesPreview({ ...imagesPreview, ...images });

                                }

                            });
                    });

                }, false);
                reader.readAsDataURL(fileList[i]);


            }


        }

    }
    const getImageValue = (images: any) => {
        if (Object.entries(imagesPreview).length) {


            if (limit === 1) {
                images = Object.entries(imagesPreview);
                let [, img] = images[0];
                return JSON.stringify(img);
            }
            else {
                return JSON.stringify(imagesPreview);
            }
        }
        else {
            return "";
        }

    }



    return (
        <div className="mb-4"  >
            <div className="container mx-auto py-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {
                        Object.entries(imagesPreview).map(([key, image]) => {
                            return <div key={image.id} className="h-32 relative">
                                <img className="h-auto max-h-full max-w-full rounded-lg mx-auto" src={image.image} />
                                <Delete
                                    height={20}
                                    width={20}
                                    onClick={(e: any) => {
                                        if (window.confirm("Are you sure ?") === true) {
                                            console.log(image);
                                            if (image?.new) {
                                                if (image?.signedUrl) {
                                                    fetch(image.signedUrl, {
                                                        method: 'DELETE'
                                                    });
                                                }
                                                let tempImages: any = { ...imagesPreview };
                                                delete tempImages[key];
                                                setImagesPreview(tempImages);
                                            }
                                            else {
                                                let tempImages: any = { ...imagesPreview };
                                                delete tempImages[image.id];
                                                setImagesPreview(tempImages);
                                            }
                                        }
                                    }}
                                    className="absolute bg-white top-1 right-1 cursor-pointer rounded-sm"
                                />
                            </div>
                        })
                    }
                </div>
                {(Object.entries(imagesPreview).length < limit) && <div>
                    <label
                        htmlFor={id}
                        className="mb-4 font-bold text-gray-700 cursor-pointer hover:text-blue-500 transition duration-300"
                    >

                        <svg className="svg-icon h-10" viewBox="0 0 20 20">

                            <path d="M18.555,15.354V4.592c0-0.248-0.202-0.451-0.45-0.451H1.888c-0.248,0-0.451,0.203-0.451,0.451v10.808c0,0.559,0.751,0.451,0.451,0.451h16.217h0.005C18.793,15.851,18.478,14.814,18.555,15.354 M2.8,14.949l4.944-6.464l4.144,5.419c0.003,0.003,0.003,0.003,0.003,0.005l0.797,1.04H2.8z M13.822,14.949l-1.006-1.317l1.689-2.218l2.688,3.535H13.822z M17.654,14.064l-2.791-3.666c-0.181-0.237-0.535-0.237-0.716,0l-1.899,2.493l-4.146-5.42c-0.18-0.237-0.536-0.237-0.716,0l-5.047,6.598V5.042h15.316V14.064z M12.474,6.393c-0.869,0-1.577,0.707-1.577,1.576s0.708,1.576,1.577,1.576s1.577-0.707,1.577-1.576S13.343,6.393,12.474,6.393 M12.474,8.645c-0.371,0-0.676-0.304-0.676-0.676s0.305-0.676,0.676-0.676c0.372,0,0.676,0.304,0.676,0.676S12.846,8.645,12.474,8.645"></path>
                        </svg>


                        {label}
                    </label>
                    <input
                        id={id}
                        type="file"

                        className="opacity-0 absolute"
                        onChange={preparePreview}

                        multiple
                    />
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                </div>}
                <input
                    name={id}
                    className="opacity-0 absolute"
                    required={required}
                    value={getImageValue(imagesPreview)}
                    readOnly
                />

            </div>
        </div>);
}

export default Images;