import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from '../app/store'
import { selectDialCode,selectLocale } from '../features/auth/authSlice'
import t from "../app/services/translation";

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useDialCode = () => {
  return useSelector(selectDialCode)
}
export const useLocale = () => {
  return useSelector(selectLocale)
}
export const useTranslation = () => {
    const locale = useLocale();
    return (str:string, args:string[] = [], lo = "none")=>{
        if( lo === "none" ) lo = locale;
        return t(str,args,lo);
    };
}