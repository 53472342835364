import React, { useState, useContext } from 'react';
import Login from './Login';
import Signup from './Signup';
import Confirm from './Confirm';
import Mfa from './Mfa';
import Forgot from './Forgot';
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials, setCurrentBusiness } from '../auth/authSlice'
import type { User } from '../../app/services/auth'
import { useNavigate } from 'react-router-dom'
import Toast from '../toast/messages';
import { useGetPaymentQuery } from "../../app/services/api/payment";

import {
    addToast
} from '../toast/toastSlice';
import {
    CognitoUserPool,
    //CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
import { fetchData } from '../../app/services/apiService';
import { UserContext } from '../../utils/UserContext';
import PageLoader from '../loader/PageLoader';
import { setLoader } from '../loader/loaderSlice';



const LoginSignupForm = (props: any) => {

    const { setDiUser } = useContext(UserContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    var poolData = {
        UserPoolId: process.env.REACT_APP_POOL_ID ?? '', // Your user pool id here
        ClientId: process.env.REACT_APP_CLIENT_ID ?? '', // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);


    const [user, setUser] = useState({
        username: '',
        email: '',
        password: ''
    });

    var cognito = userPool.getCurrentUser()!;
    const type = props.type ? props.type : 'login';
    const [formType, setFormType] = useState(type);
    //const [email, setEmail] = useState('');
    // [password, setPassword] = useState('');
    //const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmationData, setConfirmationData] = useState({});
    const [cognitoUser, setCognitoUser] = useState(cognito);

    const authenticate = (user: any) => {
        var authenticationData = {
            Username: user.username!,
            Password: user.password,
        };
        //console.log(authenticationData);
        var authenticationDetails = new AuthenticationDetails(
            authenticationData
        );

        var userData = {
            Username: user.username!,
            Pool: userPool,
        };
        var cognitoUser = new CognitoUser(userData);


        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                let token = result.getAccessToken().getJwtToken();
                let idToken = result.getIdToken().getJwtToken();
                cognitoUser.getUserData(async function (err: any, userData: any) {
                    if (err) {
                        console.log(err.message || JSON.stringify(err));
                        dispatch(addToast({ type: "error", message: err.message || JSON.stringify(err) }));
                        return;
                    }
                    else {
                        let user: User = {};
                        if (userData && userData.UserAttributes) {
                            userData.UserAttributes.forEach((element: { Name: string | number; Value: string; }) => {
                                user[element.Name] = element.Value;
                            });
                            localStorage.removeItem('userData');
                            setDiUser({ user_cred: { user: user, token: token, idToken: idToken } });
                            dispatch(setCredentials({ user: user, token: token, idToken: idToken }));
                            // dispatch(addToast({ type: "success", message: "Loggedin successfully." }));
                            const getUserBusiness = await fetchData('user');

                            if (getUserBusiness.additionalData != undefined) {
                                var userAdditionaData = getUserBusiness.additionalData;
                                setDiUser({ user_data: userAdditionaData });

                                if (false && userAdditionaData.length > 1) {
                                    navigate('/listBusiness');
                                } else {
                                    setDiUser({ current_business: userAdditionaData[0] });
                                    dispatch(setCurrentBusiness(userAdditionaData[0]));
                                    navigate('/panel');

                                }

                            } else {
                                navigate('/business');
                                dispatch(setLoader(false));
                            }
                            dispatch(addToast({ type: "success", message: "Loggedin successfully." }));

                        }
                    }
                    // console.log('User data for user ', userData);
                });
            },
            onFailure: function (err) {
                if (err.name === 'UserNotConfirmedException') {
                    cognitoUser.resendConfirmationCode(function (err, result) {
                        if (err) {
                            console.log('confirmation failure:', err.name, err.message);
                            return;
                        }

                        setConfirmationData(result.CodeDeliveryDetails);
                        console.log(result.CodeDeliveryDetails);
                        setFormType('confirm');

                    });

                } else {
                    dispatch(addToast({ type: "error", message: err.message || JSON.stringify(err) }));
                }
                //console.log('login failure:', err.name, err.message);
                dispatch(setLoader(false));
            },
            mfaRequired: function (codeDeliveryDetails) {
                // MFA is required to complete user authentication.
                // Get the code from user and call
                //console.log('codeDeliveryDetails', codeDeliveryDetails);
                //setConfirmationData(codeDeliveryDetails);

                setFormType('mfa');
                setCognitoUser(cognitoUser);

            },
        });
        //console.log(user.email, user.password);

    }
    const renderLink = (formType: string) => {
        switch (formType) {
            case 'mfa':
                return (<>
                    <button
                        className=" text-indigo-500  py-2 px-4 "
                        onClick={(e) => { e.stopPropagation(); setFormType('login'); }}
                    >
                        Log in
                    </button>

                </>);
            case 'forgot':
                return (<></>);
            case 'signup':
                return (<></>);
            case 'confirm':
                return (<></>);
            default:
                return (<>
                    <button
                        className=" text-indigo-500 pt-2 px-4 "
                        onClick={(e) => { e.stopPropagation(); setFormType(formType === 'login' ? 'signup' : 'login'); }}
                    >
                        {formType === 'login' ? 'Create new account' : 'Already have account?'}
                    </button>
                    <button
                        className=" text-indigo-500 px-4 "
                        onClick={(e) => { e.stopPropagation(); setFormType('forgot'); }}
                    >
                        Forgot Password
                    </button>
                </>);
        }
    }
    const renderComponent = (formType: string) => {

        switch (formType) {
            case 'login':
                return <Login formType={formType} setFormType={setFormType} user={user} setUser={setUser} setConfirmationData={setConfirmationData} setCognitoUser={setCognitoUser} authenticate={authenticate} />
            case 'signup':
                return <Signup setFormType={setFormType} setConfirmationData={setConfirmationData} setUser={setUser} />
            case 'confirm':
                return <Confirm user={user} confirmationData={confirmationData} setFormType={setFormType} />
            case 'forgot':
                return <Forgot user={user} confirmationData={confirmationData} setFormType={setFormType} />
            case 'mfa':
                return <Mfa confirmationData={confirmationData} cognitoUser={cognitoUser} user={user} authenticate={authenticate} />
            default: return <></>;
        }
    }


    return (
        <>
            <div className="bg-[#99defd]  min-h-screen flex flex-col gap-12 relative  justify-center items-center p-10 ">
                <Toast></Toast>
                <PageLoader />
                <div className=" flex flex-col w-full lg:w-2/5 justify-center gap-4 md:px-10 px-3 py-5  rounded-xl  font-gtbuddy bg-white ">
                    {
                        renderComponent(formType)
                    }
                    {
                        renderLink(formType)
                    }

                </div>

            </div>
        </>
    );
}
export default LoginSignupForm;