import { Crud } from "../crud/Crud";
import { useGetActivitiesQuery, activityApi } from "../../app/services/api/activity";
import { useEffect, useState } from "react";
import { UpDown } from "../icons/UpDown";
import { CollapsibleContent } from "../forms/CollapsibleContent";
import Input from "./fields/input";
import Text from "../forms/form/Fields";
import { Link, useNavigate, useParams } from "react-router-dom";
import { s3Api } from "../../app/services/api/s3";
import { useDispatch } from "react-redux";
import { addToast } from "../toast/toastSlice";
import Modal from "../modal/Modal";
import t from "../../app/services/translation";

const activitySchema = {
    primaryKey: 'sk',
    title: 'Activities',
    entityCode: 'activity',
    attributes: {
        pk: {
            title: "Primary Key",
            code: "pk",
            dataIndex: "pk",
            required: true
        },
        sk: {
            title: "Sk",
            code: "sk",
            dataIndex: "sk",
            required: true
        },
        input: {
            title: "Input",
            code: "input",
            dataIndex: "input",
            type: "input"
        },


    },
    headers: {
        pk: {},
        sk: {},
        input: {

        },
        action: {
            title: "Action",
            code: "action",
            actions: {
                approve: {
                    title: t('Approve'),
                    class: 'text-indigo-600 hover:text-indigo-900 mr-4 cursor-pointer',
                    to: './edit/:id?entity=:entity',
                    onClick: (event: any, data: any, addMessage: any) => {
                        event.preventDefault();
                        if (window.confirm(t('Really want to Approve %s ?', [data.pk]))) {
                            fetch(process.env.REACT_APP_API_URL + "step-activity?action=approve&id=" + data.sk)
                                .then(data => data.json())
                                .then((data) => {
                                    addMessage("success", "Activity approved successfully.");
                                }).catch((e) => {
                                    addMessage("error", "Unable to approve activity.");
                                });;

                        }
                    }
                },
                disapprove: {
                    title: t('Disapprove'),
                    class: 'text-indigo-600 hover:text-indigo-900 mr-4 cursor-pointer',
                    to: './edit/:id?entity=:entity',
                    onClick: (event: any, data: any, addMessage: any) => {
                        console.log(data);
                        event.preventDefault();
                        if (window.confirm(t('Really want to Disapprove %s ?', [data.pk]))) {
                            fetch(process.env.REACT_APP_API_URL + "step-activity?action=disapprove&id=" + data.sk)
                                .then(data => data.json())
                                .then((data) => {
                                    addMessage("success", "Activity disapproved successfully.");
                                })
                                .catch((e) => {
                                    addMessage("error", "Unable to disapproved activity.");
                                });


                        }
                    }
                },
            }
        }

    }
}

export const FormExtraFields = (props: any) => {
    return <div>
    </div >
}
type ActionProps = {
    setAction?: any,
    title: string
}
const ApproveAction = ({ setAction, title }: ActionProps) => {

    return (
        <>
            <Link
                to={"./"}
                onClick={(data) => { console.log(data); }}
                className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 cursor-pointer">
                Approve
            </Link>
        </>
    );
}
export const Activity = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [startKeyHistory, setStartKeyHistory] = useState([null]);
    const currentStartKey = startKeyHistory[currentPage];

    const params = `exclusiveStartKey=${currentStartKey ? encodeURIComponent(JSON.stringify(currentStartKey)) : ''}`;

    const { data, isLoading } = useGetActivitiesQuery(params);

    const handlePrevPage = () => {
        if(currentPage > 0) {
            setCurrentPage(currentPage-1);
        }
    }

    const handleNextPage = () => {
        if(data?.lastEvaluatedKey){
            setCurrentPage(currentPage+1);
        }
    }

    useEffect(() => {
        if(data && currentPage === 0){
            setStartKeyHistory([null]);
        }
    }, [data, currentPage]);

    useEffect(() => {
        if(data && data.lastEvaluatedKey){
            const updatedHistory = [...startKeyHistory];
            updatedHistory[currentPage+1] = data.lastEvaluatedKey;
            setStartKeyHistory(updatedHistory);
        }
    }, [data])

    return isLoading ? <></> : (
        <Crud title="Activity"
            gridRoute="/panel/manage/activity"
            schema={activitySchema}
            data={isLoading ? [] : data?.activities}
            allowedList={{ list: true, add: false, view: false, edit: false }}
            actions={{}}
            apiEndPoints={activityApi.endpoints}
            FormExtraFields={FormExtraFields}
            page={currentPage+1}
            onPrevPage={handlePrevPage}
            onNextPage={handleNextPage}
        /*massAction={{ 'selectedValues': { '3a85f5be-e65d-4fb3-99e5-a27963bd2882': '3a85f5be-e65d-4fb3-99e5-a27963bd2882' } }}*/
        />
    );
}