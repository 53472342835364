import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Input from "../fields/input";
import Image from "../fields/image";
import { UserContext } from '../../../utils/UserContext';
import {
    addToast
} from '../../toast/toastSlice';
import { fetchData } from '../../../app/services/apiService';
import ImageCropper from './ImageCroper/ImageCropper';
import { useGetThemeQuery } from '../../../app/services/api/theme';
import { useNavigate } from 'react-router-dom'
import { setLoader } from '../../loader/loaderSlice';
import PageLoader from '../../loader/PageLoader';
import Toast from "../../toast/messages"
import { selectCurrentBusiness } from '../../auth/authSlice';
import { merge } from '../../../app/services/merge';

interface TreeNodeProps {
    node: {
        code: string;
        name: string;
        children?: TreeNodeProps['node'][];
        component_handle?: string;
    };
}

interface TreeProps {
    data: TreeNodeProps['node'][];
}
type FormData = {
    [key: string]: string | NestedFormData;
};

type collapseData = {
    [key: string]: boolean;
};

type NestedFormData = {
    [key: string]: string | NestedFormData;
};
interface Component {
    name: string;
    code: string;
    component_handle: string;
    value?: string;
    children?: Component[];
}

interface Result {
    [key: string]: Result | string;
}

interface Pages {
    name?: string;
    code?: string;
    url?: string;
    content?: string;
}

interface ColorPickerProps {
    initialColor: string;
    handleChange: (color: string) => void;
}


const ChildNode: React.FC<TreeNodeProps & { level: number, className?: string, finalData: Object; formData: FormData; nameNode: string; onFormDataChange: ({ }) => void, collapsState: collapseData, toggleCollapseChange: (buttonId: string) => void }> = ({ level, className, node, formData, onFormDataChange, nameNode, finalData, collapsState, toggleCollapseChange }) => {
    const currentBusiness = useSelector(selectCurrentBusiness);
    const { code, pageCode, id } = useParams();
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name, value } = event.target;
        let iframe: any = document.getElementById("demo-frame");
        iframe.contentWindow.postMessage({ "action": "update-data", "field": pageCode + "." + name, value: value }, '*');
        const keys = name.split('.');
        const result: any = {};
        keys.reduce((acc, key, index) => {
            acc[key] = index === keys.length - 1 ? value : {};
            return acc[key];
        }, result);
        onFormDataChange(result);
    };

    const { user } = useContext(UserContext);

    const imageUploadOnBucket = async (image: string, identifier: string) => {

        let iframe: any = document.getElementById("demo-frame");
        iframe.contentWindow.postMessage({ "action": "update-data", "field": pageCode + "." + identifier, value: image }, '*');
        try {
            const keys = identifier.split('.');
            const imagePath = identifier.replace(/\./g, '/');
            const s3FileName = `business/${currentBusiness?.id}/themes/${code}/${id}/${imagePath}.jpeg`;
            let s3Bucket = process.env.REACT_APP_S3_BUCKET;
            const signedUrl = await fetchData(`signed-s3?file_name=${s3FileName}&bucket_name=${s3Bucket}`);
            let imageUpload = image.replace(/^data:image\/\w+;base64,/, "");
            const imageData = atob(imageUpload);
            const arrayBuffer = new ArrayBuffer(imageData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < imageData.length; ++i) {
                view[i] = imageData.charCodeAt(i);
            }
            const blob = new Blob([view], { type: 'image/jpeg' });
            let imageUploadRes = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/*',
                    'x-amz-tagging': 'public=yes'
                },
                body: blob
            });
            console.log(imageUploadRes, 'imageUploadRes');
            let imageUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}${s3FileName}`;
            const result: any = {};
            keys.reduce((acc, key, index) => {
                acc[key] = index === keys.length - 1 ? imageUrl : {};
                return acc[key];
            }, result);
            onFormDataChange(result);
            //console.log(image, id, 'props');
        } catch (error) {
            console.error('Error uploading image:', error);
            // Handle error appropriately
        }
    }

    const rgbaToHex = (rgba: string): string => {
        if (!rgba) return '';
        const regex = /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)/;
        const matches = rgba?.match(regex);
        if (!matches) return '';

        const r = parseInt(matches[1], 10);
        const g = parseInt(matches[2], 10);
        const b = parseInt(matches[3], 10);
        const a = parseFloat(matches[4]);

        const toHex = (component: number) => {
            const hex = component.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round(a * 255))}`;
    }

    const handleColorChange = (color: string) => {
        const syntheticEvent = {
            preventDefault: () => { },
            target: {
                name: nameNode,
                value: color
            }
        } as React.ChangeEvent<HTMLInputElement>;
        handleInputChange(syntheticEvent);

    };

    if (!node.children) {
        const getNestedValue = (obj: any, path: string) => {
            const keys = path.split('.');
            return keys.reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
        };
        let val = getNestedValue(finalData, nameNode);
        const inputValue = typeof val === "undefined" ? "" : val.toString();

        switch (node.component_handle) {

            case 'input':
                return (
                    <div className={" m-3 text-sm border-b  " + className}>
                        <h2 className="pb-3 text-xs">{node.name}</h2>
                        <Input onChange={handleInputChange} name={nameNode} value={inputValue?.toString() || ''} type="text" />
                    </div>
                );
            case 'image':
                return (
                    <div className={"m-3 text-sm border-b  " + className}>
                        <h2 className="text-xs">{node.name}</h2>
                        <Input onChange={handleInputChange} id={nameNode + "-proxy"} name={nameNode} value={inputValue?.toString() || ''} type="hidden" />

                        <ImageCropper afterImageCrop={imageUploadOnBucket} id={nameNode} imagePreview={inputValue?.toString() || ''} />
                    </div>
                );
            case 'color-picker':
                return (
                    <div className={"m-3 text-sm  border-b pb-5  " + className} >
                        <h2 className="pb-3 text-xs">{node.name}</h2>
                        {/* <Input onChange={handleInputChange} id={nameNode + "-proxy"} name={nameNode} value={rgbaToHex(inputValue) || ''} type="hidden" /> */}
                        <ColorPicker initialColor={inputValue[0] === "#" ? inputValue : (rgbaToHex(inputValue) || '#ff12a145')} handleChange={handleColorChange} />
                    </div>
                )
            default:
                return null;
        }
    } else {
        return (
            // input box code
            <div className={" flex flex-col bg-white " + className + " level-" + level}>
                <div className="flex justify-between items-center p-3 border-b border-[#e5e7eb] hover:bg-blue-100 hover:cursor-pointer"
                    id={nameNode}
                    onClick={(e) => { toggleCollapseChange(e.currentTarget.id) }}
                >
                    <h2 className="text-sm font-normal ">{node.name}</h2>
                    
                        {collapsState[nameNode] ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 " viewBox="0 0 24 24" fill="">
                        <path d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z" />
                    </svg>
                    
                         :
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 0 24 24"
                                fill=""><path d="M12 17.414 3.293 8.707l1.414-1.414L12
                                        14.586l7.293-7.293 1.414 1.414L12 17.414z" /></svg>}
                    
                </div>

                {node.children && node.children.map(item => {
                    let className = collapsState[nameNode] ? '' : 'hidden';
                        
                      
                    return <ChildNode
                        level={level + 1}
                        className={className}
                        key={item.code}
                        node={item}
                        nameNode={nameNode + '.' + item.code}
                        onFormDataChange={onFormDataChange}
                        formData={typeof formData !== 'undefined' && typeof formData[node.code] !== 'undefined' ? formData[node.code] as NestedFormData : formData}
                        finalData={finalData}
                        collapsState={collapsState}
                        toggleCollapseChange={toggleCollapseChange} />
                })}
            </div>
        );
    }

};
const ThemeForm: React.FC<TreeProps & { level: number, handleSave: any, themePages: Pages[] | null, formData: FormData, onFormDataChange: ({ }) => void, collapsState: collapseData, toggleCollapseChange: (buttonId: string) => void }> = ({ level, handleSave, themePages, data, formData, onFormDataChange, collapsState, toggleCollapseChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { pageCode, id } = useParams();
    let currentPageObj: Pages | null = null;
    let updatePageJson: Pages[] | null = null;
    if (themePages) {
        currentPageObj = themePages.find((item: Pages) => item.code === pageCode) ?? null;
        updatePageJson = themePages.filter((item: Pages) => item.code !== pageCode);
    }
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className="font-gtbuddy">
                {data.map(rootNode => (
                    <div key={rootNode.code} className=" ">

                        <ChildNode
                            key={rootNode.code}
                            level={level + 1}
                            node={rootNode}
                            nameNode={rootNode.code}
                            onFormDataChange={onFormDataChange}
                            formData={typeof formData !== 'undefined' && typeof formData[rootNode.code] !== 'undefined' ? formData[rootNode.code] as NestedFormData : formData}
                            finalData={formData} collapsState={collapsState}
                            toggleCollapseChange={toggleCollapseChange} />
                    </div>
                ))}
            </div>
        </>
    );
};

const ColorPicker: React.FC<ColorPickerProps> = ({ initialColor, handleChange }) => {
    const hexColor = initialColor.slice(0, 7);
    const alphaHex = initialColor.slice(7, 9);
    const alphaDecimal = parseInt(alphaHex, 16);
    const alphaPercentage = (alphaDecimal / 255) * 100;

    const [color, setColor] = useState(hexColor);
    const [alpha, setAlpha] = useState(alphaPercentage);

    useEffect(() => {
        setColor(hexColor);
        setAlpha(alphaPercentage);
    }, [hexColor, alphaPercentage]);

    const hexToRgba = (hex: string, alpha: number) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 7) {
            r = parseInt(hex.slice(1, 3), 16);
            g = parseInt(hex.slice(3, 5), 16);
            b = parseInt(hex.slice(5, 7), 16);
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setColor(e.target.value);
        updateFinalColor(e.target.value, alpha);
    };

    const handleAlphaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAlpha(parseInt(e.target.value, 10));
        updateFinalColor(color, parseInt(e.target.value, 10));
    };

    const updateFinalColor = (color: string, alpha: number) => {
        const rgbaColor = hexToRgba(color, alpha / 100);
        handleChange(rgbaColor);
    };

    return (
        <div className='flex flex-row justify-between items-center'>
            <input className='max-w-[50%]' type='color' name='color' value={color.toString()} onChange={handleColorChange} ></input>
            <input className='max-w-[50%]' type="range" name='alpha' min={0} max={100} value={alpha.toString()} onChange={handleAlphaChange} ></input>
        </div>
    )
}

function convertJsonToReactObject(json: Component[]): Result {
    const result: Result = {};
    json.forEach((item) => {
        if (item.children) {
            result[item.code] = convertJsonToReactObject(item.children);
        } else {
            result[item.code] = item.value || "";
        }
    });

    return result;
}


const Theme = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = (e: any) => {
        setIsOpen(!isOpen);
    };

    let currentPageObj: Pages | null = {};
    let updatePageJson: Pages[] | null = [];

    // const toggleSwitch = () => {
    //     if (!isChecked) {
    //         let iframe: any = document.getElementById("demo-frame");
    //         iframe.contentWindow.postMessage({ "action": "bind-event" }, '*');
    //     }
    //     setIsChecked((prev) => !prev);
    // };

    const { code, type, pageCode, id } = useParams();

    const dispatch = useDispatch();
    const [collapsState, setCollapsState] = useState<collapseData>({});
    const { user } = useContext(UserContext);
    const currentBusiness = useSelector(selectCurrentBusiness);
    const { data: theme, isLoading } = useGetThemeQuery("id=" + encodeURIComponent(type + "#" + code));
    const [formData, setStateFormData] = useState({});
    var globalFormData: any = {};
    const setFormData = (data: any) => {
        globalFormData = data;
        setStateFormData(data);
    }

    const [designData, setDesign] = useState<TreeNodeProps['node'][] | []>([]);
    const s3FileName = `business/${currentBusiness?.id}/themes/${code}/${id}/${pageCode}.json`;
    const s3FileUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}${s3FileName}`;
    const pagesJsonUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${type}/${code}/pages.json`;
    const [themePages, setThemePages] = useState<Pages[] | null>(null);
    if (themePages) {
        currentPageObj = themePages.find((item: Pages) => item.code === pageCode) ?? null;
        updatePageJson = themePages.filter((item: Pages) => item.code !== pageCode);
    }
    const CustomMessageEvent = (event: any) => {
        if (event.data.action == "child-updates-found") {
            let iframe: any = document.getElementById("demo-frame");
            iframe.contentWindow.postMessage({ "action": "bind-event" }, '*');
        }
        if (event.data.action == 'update-field') {
            if (event?.data?.field) {
                let data = event.data.field.split('.');
                data.shift();
                let updatedObject: any = { ...globalFormData };
                let lastObject = updatedObject;
                let stateReference: any = globalFormData;
                let i;

                for (i = 0; i < (data.length - 1); i++) {
                    if (typeof stateReference === 'undefined') {
                        stateReference = {};
                        lastObject[data[i]] = {};
                    }
                    else {
                        lastObject[data[i]] = { ...stateReference[data[i]] };
                    }
                    lastObject = lastObject[data[i]];
                    stateReference = stateReference[data[i]];
                }
                lastObject[data[i]] = event.data.value;
                handleFormDataChange(updatedObject);
            }

        } else if (event.data.action == 'prepare-image-components') {
        }
        else if (event.data.action == 'trigger-click') {
            if (event.data.field) {
                let data = event.data.field.split('.');
                data.shift();

                let element = data.join(".");
                data.pop();
                let state: any = {};
                while (data.length > 0) {
                    state[data.join(".")] = true;
                    data.pop();
                }
                openTabs(state);

                document.getElementById(element)?.click();

                document.getElementById(element)?.scrollIntoView();
            }


        }
    }
    useEffect(() => {

        const loadJsonFile = async () => {
            try {

                if (!pageCode) {
                    return;
                }
                dispatch(setLoader(true));
                const allPages = await fetch(pagesJsonUrl, { cache: "no-store" });
                const pagesJson = await allPages.json();
                setThemePages(pagesJson);
                const themeUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${type}/${code}/${pageCode}.json`;
                let data: any = {};
                try {
                    const response = await fetch(themeUrl, { cache: "no-store" });
                    data = await response.json();
                }
                catch (e) {

                }
                const defaultUrl = `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${type}/${code}/defaultData.json`;
                let defaultData: any = {};
                try {
                    const response = await fetch(defaultUrl, { cache: "no-store" });
                    defaultData = await response.json();
                }
                catch (e) {

                }
                setDesign(data);
                const reactObject = convertJsonToReactObject(data);
                handleFormDataChange(reactObject);
                console.log(s3FileUrl);
                let savedThemeData: any = { ok: false };
                let savedThemeDataJson = {};
                try {
                    savedThemeData = await fetch(s3FileUrl, { cache: "no-store" });
                    if (savedThemeData.ok) {
                        savedThemeDataJson = await savedThemeData.json();
                    }
                }
                catch (e) {

                }
                savedThemeData = merge(savedThemeData, defaultData);
                if (savedThemeData.ok) {
                    setFormData(savedThemeDataJson);
                } else {
                    const defaultThemeData = `${process.env.REACT_APP_THEME_BUCKET_URL}themes/${type}/${code}/defaultData.json`;
                    console.log(defaultThemeData);
                    let defaultThemeResponse: any = {};
                    try {
                        defaultThemeResponse = await fetch(defaultThemeData, { cache: "no-store" });
                    }
                    catch (e) {

                    }

                    if (defaultThemeResponse && pageCode) {
                        const defaultThemeResponseJson = await defaultThemeResponse.json();
                        setFormData(defaultThemeResponseJson[pageCode]);
                    }

                }
                dispatch(setLoader(false));
            } catch (error) {
                console.error('Error loading JSON file:', error);
                dispatch(setLoader(false));
            }
        };

        loadJsonFile();
        window.addEventListener('message', CustomMessageEvent);
    }, [pageCode]);

    useEffect(() => {
        const handleElsewhereClick = (e: any) => {
            const dropdown = document.getElementById("dropdownMenu");

            if (dropdown && !dropdown.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleElsewhereClick);

        return () => {
            document.removeEventListener("mousedown", handleElsewhereClick);
        };
    }, []);


    const handleFormDataChange = (obj: {}) => {
        //console.log('formData', formData);
        const data = mergeObjects(obj, formData);
        //console.log('setting form data ', data);
        globalFormData = data;
        setFormData(data);

    };


    const toggleCollapse = (buttonId: string) => {
        let currentCollapseCode = collapsState[buttonId];
        if (typeof currentCollapseCode === 'undefined') {
            currentCollapseCode = true;
        } else {
            currentCollapseCode = !currentCollapseCode;
        }
        const data = {
            [buttonId]: currentCollapseCode
        };
        const a = {
            ...collapsState,
            ...data,
        };
        setCollapsState(a)
    }

    const openTabs = (state: any) => {
        const a = {
            ...collapsState,
            ...state,
        };
        setCollapsState(a)
    }


    const mergeObjects = (obj1: any, obj2: any) => {
        const mergedObject = {
            ...obj2,
            ...obj1,
        };
        for (const key in obj1) {
            if (typeof obj1 === 'object' && typeof obj2 === 'object') {
                if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) && typeof obj1[key] === 'object' && obj1[key] !== null) {
                    mergedObject[key] = mergeObjects(obj1[key], obj2[key]);
                }
            }
            else {
                console.log('unable to merge ', obj1, obj2);
            }

        }
        return mergedObject;
    };



    const handleSave = async () => {
        console.log("here code");
        dispatch(setLoader(true));
        let s3Bucket = process.env.REACT_APP_S3_BUCKET;
        //business/business-id/themes/theme-code.json
        const getSignedUrl = await fetchData(`signed-s3?file_name=${s3FileName}&bucket_name=${s3Bucket}`);
        await fetch(getSignedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-amz-tagging': "public=yes"
            },
            body: JSON.stringify(formData),
            cache: "no-store"
        });
        dispatch(setLoader(false));
        dispatch(addToast({ type: "success", message: "Theme Saved Successfully." }));
    }


    // save button here
    const navigate = useNavigate()
    const GoHomePage = () => {
        navigate("/panel/themes")
    }
    let iframeUrl = `https://main.${theme?.amplifyDefaultDomain}/demo/${currentBusiness?.id}/${id}/${theme?.code}`;
    return (
        <div className=" md:overflow-y-hidden  h-full">
            <Toast></Toast>
            <PageLoader />
            <header className=" sticky top-0 py-2 w-full flex justify-between items-center 
            border-b border-[#e5e7eb] font-gtbuddy text-sm
            font-semibold px-10 ">
                <div className=" cursor-pointer justify-center">
                    {/* <label htmlFor="toggle" className="flex items-center" >
                        <div className="relative">
                            <input
                                id="toggle"
                                type="checkbox"
                                className="sr-only peer"
                                checked={isChecked}
                                onChange={toggleSwitch}
                            />
                            <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                            <div
                                className={`${isChecked ? 'bg-green-500 translate-x-full' : 'bg-gray-200'
                                    } absolute left-0 top-0 w-8 h-8 rounded-full peer-checked:after:translate-x-full`}
                            ></div>
                        </div>
                        <div className="ml-3 text-gray-700 font-medium">Change Input View</div>
                    </label> */}
                    <div onClick={GoHomePage} className="hover:cursor-pointer">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" fill="#000000" stroke="#000000" stroke-width=".75"/>
                    </svg>

                    </div>
                </div>
                <div id="dropdownMenu" className=" relative items-center justify-center">
                    <button
                        type="button"
                        className="inline-flex justify-center gap-2 items-center w-full  rounded-[4px] border border-[#e5e7eb] shadow-sm px-4 py-1  bg-white font-medium  hover:bg-gray-50 "
                        aria-haspopup="true"
                        aria-expanded="true"
                        onClick={toggleDropdown}
                    >
                        {currentPageObj && currentPageObj.name}
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 0 24 24"
                                fill=""><path d="M12 17.414 3.293 8.707l1.414-1.414L12
                                        14.586l7.293-7.293 1.414 1.414L12 17.414z" /></svg>
                    </button>
                    {isOpen && updatePageJson && updatePageJson.length > 0 && (
                        <div
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            <div className="py-1" role="none">
                                {updatePageJson.map(rootNode => (
                                    <a href={`${rootNode.url}/${id}`}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                        tabIndex={-1}
                                        key={rootNode.code}
                                    >
                                        {rootNode.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className=" z-10  justify-center ">
                    <button className=" bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-4 rounded" onClick={handleSave}>
                        Save
                    </button>
                </div>

            </header>
            <div className=" flex h-screen flex-col gap-5 md:flex-row md:gap-0">
                <div className={` mx-auto pb-4 overflow-y-auto md:w-1/3 w-full border-r border-[#e5e7eb] `} >
                    <ThemeForm level={0} handleSave={handleSave} themePages={themePages} data={designData} onFormDataChange={handleFormDataChange} formData={formData} collapsState={collapsState} toggleCollapseChange={toggleCollapse} />
                </div>

                <iframe
                    id="demo-frame"
                    src={theme?.amplifyDefaultDomain && iframeUrl}
                    title="Theme Customise"
                    width="100%"
                    height="100%"
                    className={`mx-auto  object-cover iframe-style `}
                    allowFullScreen
                />
            </div>
        </div>
    )

}
export default Theme;