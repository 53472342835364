 import Modal from "../../../modal/Modal"
 import RightIcon from "../../../icons/RigthIcone"
import { useState ,useContext} from "react"
import paymentmata from "../../../../images/paymentmata.jpg"
import QR from "../qr";
import { useChoosePlanMutation } from "../../../../app/services/api/payment";
import { UserContext } from '../../../../utils/UserContext';

interface CartProps {
    code: string,
    title: string,
    description?: string,
    price: number,
    sort_order?:number
    badge?: string,
    features?: Features[]
}

interface Features {
    title ? : string;
}
interface RedirectInfo {
    url?: string;
}

interface ChoosePlanResponse {
    redirectInfo?: RedirectInfo;
    // Add any other properties that may be in the response
}
interface activePlanData {
    pk?: string;
}

const Cart:React.FC<CartProps>=({code,title,price, description, features})=>{
    const { user } = useContext(UserContext)
    const activePlan = user.current_active_plan || [];
    let activePlanData: activePlanData | null = null;
    if(activePlan.data && typeof activePlan.data !== "undefined")
    {
        activePlanData = JSON.parse(activePlan.data);
        if(typeof activePlanData ==="string")
        activePlanData = JSON.parse(activePlanData);
       
        
    }
    const [choosePlan] = useChoosePlanMutation();

    const [isOpen,setIsOpen]=useState(false)
    const choosePayment=async(planCode: string)=>{
        try {
            const res = await choosePlan({ code: planCode,currentUrl:window.location.href }).unwrap();
            if (res.redirectInfo) {
                const targetUrl = res.redirectInfo.url; 
                window.location.href = targetUrl;
                console.log("here");

            }
        } catch (error) {
            console.error('Error choosing plan:', error);
            // Handle error (e.g., show notification)
        }
    }
    return(
        <>
        <div className="w-60 flex flex-col  justify-center bg-white p-5
        rounded-lg text-[12px] hover:outline hover:outline-[rgb(72,139,164)] 
        hover:outline-offset-0 ...">
            
            <h3 className="font-bold text-center text-base mt-2">{title}</h3>
            <p className="text-center py-2 border-b border-white  mb-2 
            shadow-sm text-[#488ba4] text-sm font-bold ">  &#x20b9; {price}</p>
            <p className="font-bold pb-3">{ description}</p>
            <ul>
            {features?.map((child,index)=><li
            key={child.title}> <p className="flex gap-1 items-center"><RightIcon fill="#33b648"/>{child.title}</p></li>)}
            </ul>
            {
                activePlanData && 
                activePlanData.pk !== undefined && 
                activePlanData.pk === code ? (
                    // If the active plan is valid and matches the code, render nothing or any other content
                    null // or <div>Your plan is active.</div> if you want to show a message
                ) : (
                    <button 
                    onClick={() => choosePayment(code)} 
                    className="bg-black text-white w-32 mx-auto py-1 px-6 rounded-md mt-3"
                    >
                    Get started
                    </button>
                )
                }
        
         </div>
         
       </>
    )
}

export default Cart;