import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User } from '../../app/services/auth'
import { getCurrentUser, userPool, AuthState } from '../../app/services/cognito'
import type { RootState } from '../../app/store'
import { Business } from '../../app/services/api/business'
import { setCurrentBusiness as setUserBusiness, logout as userLogOut } from '../../app/services/user'

let initialState: AuthState = { dialCode: '+91', locale: 'en' };
initialState = getCurrentUser();
const slice = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token, idToken } }: PayloadAction<{ user: User; token: string, idToken: string }>
    ) => {
      state.user = user
      state.token = token
      state.idToken = idToken;
    },
    setCurrentBusiness: (state, { payload }: PayloadAction<Business>) => {
      state.currentBusiness = payload;
      setUserBusiness(payload);
    },
    logout: (state) => {
      let cognito = userPool.getCurrentUser()!;
      cognito.signOut();
      state.user = null;
      state.token = null;
      state.idToken = null;
      state.currentBusiness = null;
      userLogOut();
    }
  },
})

export const { setCredentials, logout, setCurrentBusiness } = slice.actions

export default slice.reducer
export const selectDialCode = (state: RootState) => state.auth.dialCode
export const selectLocale = (state: RootState) => state.auth.locale
export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectCurrentBusiness = (state: RootState) => state.auth.currentBusiness;
export const selectTokens = (state: RootState) => ({ token: state.auth.token, idToken: state.auth.idToken });
