import React, { useContext, useState, useEffect } from "react";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Footer from "./footer";
import Business from "../../admin/business/business";
import { UserContext } from "../../../utils/UserContext";
import Toast from "../../toast/messages"
import PageLoader from "../../loader/PageLoader";
import { useNavigate } from 'react-router-dom';
import Plans from '../../admin/payment/plans';
import { useGetPaymentQuery } from "../../../app/services/api/payment";
import { current } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux';
import { setLoader } from '../../loader/loaderSlice';
export default function Admin({ children }: any) {
  const [toggle, settoggle] = useState(false);
  const { user, setDiUser } = useContext(UserContext)
  const dispatch = useDispatch();
  const user_data = user.user_data || [];
  const [isPlanActive, setIsPlanActive] = useState<boolean | null>(null);
  function handleClick() {
    settoggle(!toggle);
  }
  const { data: getActivePlan } = useGetPaymentQuery("");
  useEffect(() => {
    dispatch(setLoader(false));
  }, []);
  useEffect(() => {
    if (getActivePlan && typeof getActivePlan.pk !== "undefined") {
      setDiUser({ current_active_plan: {} });
      setDiUser({ current_active_plan: getActivePlan });
      setIsPlanActive(true);
    } else {
      setDiUser({ current_active_plan: {} });
      setIsPlanActive(false); // Handle case where the plan is not active
    }
  }, [getActivePlan]);


  return (
    <>
      <Navbar onClick={handleClick} businessNames={user_data} />
      <div className="flex fixed w-screen h-screen ">
        <div className={` ${toggle ? 'block' : 'hidden'} md:block mx-auto pb-4 md:w-1/5 w-4/5 border-r border-[#e5e7eb] `}>
          <Sidebar />
        </div>
        {/* z-0 md:static overflow-y-auto overflow-x-hidden w-full pr-12 */}
        <div className="z-0 md:static overflow-y-auto overflow-x-hidden w-full items-center">
          {isPlanActive === null ? (
            <div>Loading...</div> // Optional loading state
          ) : isPlanActive ? (
            children
          ) : (
            <Plans /> // Show plan details if the plan is not active
          )}

        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
