import React, { useState } from 'react';
import type { LoginRequest, ConfirmationData } from '../../app/services/auth'
import { useDialCode } from '../../hooks/useDialCode'
import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserAttribute,

} from 'amazon-cognito-identity-js';
import { useDispatch } from 'react-redux';
import { setLoader } from '../loader/loaderSlice';
import logo from '../../images/logo.png';
//https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js

type Props = {
    user: LoginRequest,
    formType: string,
    setFormType: any,
    setUser: any,
    setConfirmationData: any,
    setCognitoUser: any
    authenticate: any
}
function Login({ formType, setFormType, user, setUser, setConfirmationData, setCognitoUser, authenticate }: Props) {
    //console.log('dialCode',useDialCode());
    var poolData = {
        UserPoolId: process.env.REACT_APP_POOL_ID ?? '', // Your user pool id here
        ClientId: process.env.REACT_APP_CLIENT_ID ?? '', // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);

    const [email, setEmail] = useState('');
    const [dialCode, setDialCode] = useState(useDialCode());

    const [phone, setPhone] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmationData, setConfirmationDataInternal] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        dispatch(setLoader(true));
        e.preventDefault();
        authenticate(user);


    }

    function sendConfirmation() {

        let userData = {
            Username: user.username!,
            Pool: userPool,
        };
        let cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                console.log(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ', result);
        });
    }
    return (

        <> 
         <div className=" flex items-center gap-1 justify-center py-3">
                <img  src={logo}  className=" h-8 w-8  " alt=""/>
                <h1 className="font-bold text-2xl"><span>GT</span><span className=" text-[#ea7a00]">BUDDY</span></h1>
        </div>
           
            {/* <h2 className=" text-center text-3xl font-bold tracking-tight text-gray-900">
                Log in
            </h2> */}
            <form className="flex flex-col gap-4" onSubmit={(e) => { handleSubmit(e); }} action="#" method="POST">
                <div className="flex flex-col">
                    <label className="text-neutral-800" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="p-3 rounded-xl focus:outline-none focus:border-blue-500 border border-neutral-950 text-neutral-700"
                        type="tel"
                        id="email"
                        required
                        value={email}
                        onChange={(e) => { setEmail(e.target.value); setUser({ ...user, phone: e.target.value, username: e.target.value }); }}
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-neutral-800" htmlFor="password">
                        Password
                    </label>
                    <input
                        className="p-3 rounded-xl focus:outline-none focus:border-blue-500 border border-neutral-950 text-neutral-700"
                        type="password"
                        id="password"
                        required
                        value={user.password}
                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                    />
                </div>

                <button
                    className="bg-[#303030] py-3 rounded-xl text-white mt-5"
                >
                    Log in
                </button>
            </form>
        </>
    );
}
export default Login;